import { __assign, __awaiter, __generator } from "tslib";
import { getGlobalScope } from '@amplitude/analytics-client-common';
import { STORAGE_PREFIX } from '@amplitude/analytics-core';
import { openDB } from 'idb';
import { MAX_EVENT_LIST_SIZE_IN_BYTES, MAX_INTERVAL, MIN_INTERVAL } from '../constants';
import { STORAGE_FAILURE } from '../messages';
import { RecordingStatus } from './legacy-idb-types';
export var currentSequenceKey = 'sessionCurrentSequence';
export var sequencesToSendKey = 'sequencesToSend';
export var remoteConfigKey = 'remoteConfig';
export var keyValDatabaseExists = function () {
  var globalScope = getGlobalScope();
  return new Promise(function (resolve, reject) {
    if (!globalScope) {
      return reject(new Error('Global scope not found'));
    }
    if (!globalScope.indexedDB) {
      return reject(new Error('Session Replay: cannot find indexedDB'));
    }
    try {
      var request_1 = globalScope.indexedDB.open('keyval-store');
      request_1.onupgradeneeded = function () {
        if (request_1.result.version === 1) {
          request_1.result.close();
          request_1.transaction && request_1.transaction.abort();
          globalScope.indexedDB.deleteDatabase('keyval-store');
          resolve();
        }
      };
      request_1.onsuccess = function () {
        resolve(request_1.result);
      };
    } catch (e) {
      reject(e);
    }
  });
};
var batchPromiseAll = function (promiseBatch) {
  return __awaiter(void 0, void 0, void 0, function () {
    var chunkSize, batch;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (!(promiseBatch.length > 0)) return [3 /*break*/, 2];
          chunkSize = 10;
          batch = promiseBatch.splice(0, chunkSize);
          return [4 /*yield*/, Promise.all(batch)];
        case 1:
          _a.sent();
          return [3 /*break*/, 0];
        case 2:
          return [2 /*return*/];
      }
    });
  });
};
export var defineObjectStores = function (db) {
  var sequencesStore;
  var currentSequenceStore;
  if (!db.objectStoreNames.contains(currentSequenceKey)) {
    currentSequenceStore = db.createObjectStore(currentSequenceKey, {
      keyPath: 'sessionId'
    });
  }
  if (!db.objectStoreNames.contains(sequencesToSendKey)) {
    sequencesStore = db.createObjectStore(sequencesToSendKey, {
      keyPath: 'sequenceId',
      autoIncrement: true
    });
    sequencesStore.createIndex('sessionId', 'sessionId');
  }
  return {
    sequencesStore: sequencesStore,
    currentSequenceStore: currentSequenceStore
  };
};
export var createStore = function (dbName) {
  return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4 /*yield*/, openDB(dbName, 1, {
            upgrade: defineObjectStores
          })];
        case 1:
          return [2 /*return*/, _a.sent()];
      }
    });
  });
};
var SessionReplayEventsIDBStore = /** @class */function () {
  function SessionReplayEventsIDBStore(_a) {
    var loggerProvider = _a.loggerProvider,
      apiKey = _a.apiKey,
      minInterval = _a.minInterval,
      maxInterval = _a.maxInterval;
    var _this = this;
    this.storageKey = '';
    this.maxPersistedEventsSize = MAX_EVENT_LIST_SIZE_IN_BYTES;
    this.timeAtLastSplit = null;
    /**
     * Determines whether to send the events list to the backend and start a new
     * empty events list, based on the size of the list as well as the last time sent
     * @param nextEventString
     * @returns boolean
     */
    this.shouldSplitEventsList = function (events, nextEventString) {
      var sizeOfNextEvent = new Blob([nextEventString]).size;
      var sizeOfEventsList = new Blob(events).size;
      if (sizeOfEventsList + sizeOfNextEvent >= _this.maxPersistedEventsSize) {
        return true;
      }
      if (_this.timeAtLastSplit !== null && _this.interval && Date.now() - _this.timeAtLastSplit > _this.interval && events.length) {
        _this.interval = Math.min(_this.maxInterval, _this.interval + _this.minInterval);
        _this.timeAtLastSplit = Date.now();
        return true;
      }
      return false;
    };
    this.getSequencesToSend = function () {
      return __awaiter(_this, void 0, void 0, function () {
        var sequencesToSend, e_1;
        var _a;
        return __generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              _b.trys.push([0, 2,, 3]);
              return [4 /*yield*/, (_a = this.db) === null || _a === void 0 ? void 0 : _a.getAll(sequencesToSendKey)];
            case 1:
              sequencesToSend = _b.sent();
              return [2 /*return*/, sequencesToSend];
            case 2:
              e_1 = _b.sent();
              this.loggerProvider.warn("".concat(STORAGE_FAILURE, ": ").concat(e_1));
              return [3 /*break*/, 3];
            case 3:
              return [2 /*return*/, undefined];
          }
        });
      });
    };
    this.storeCurrentSequence = function (sessionId) {
      return __awaiter(_this, void 0, void 0, function () {
        var currentSequenceData, sequenceId, e_2;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              _a.trys.push([0, 4,, 5]);
              if (!this.db) {
                return [2 /*return*/, undefined];
              }
              return [4 /*yield*/, this.db.get(currentSequenceKey, sessionId)];
            case 1:
              currentSequenceData = _a.sent();
              if (!currentSequenceData) {
                return [2 /*return*/, undefined];
              }
              return [4 /*yield*/, this.db.put(sequencesToSendKey, {
                sessionId: sessionId,
                events: currentSequenceData.events
              })];
            case 2:
              sequenceId = _a.sent();
              return [4 /*yield*/, this.db.put(currentSequenceKey, {
                sessionId: sessionId,
                events: []
              })];
            case 3:
              _a.sent();
              return [2 /*return*/, __assign(__assign({}, currentSequenceData), {
                sessionId: sessionId,
                sequenceId: sequenceId
              })];
            case 4:
              e_2 = _a.sent();
              this.loggerProvider.warn("".concat(STORAGE_FAILURE, ": ").concat(e_2));
              return [3 /*break*/, 5];
            case 5:
              return [2 /*return*/, undefined];
          }
        });
      });
    };
    this.addEventToCurrentSequence = function (sessionId, event) {
      return __awaiter(_this, void 0, void 0, function () {
        var tx, sequenceEvents, eventsToSend, updatedEvents, sequenceId, e_3;
        var _a;
        return __generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              if (this.interval === 0) {
                this.interval = this.minInterval;
              }
              _b.label = 1;
            case 1:
              _b.trys.push([1, 11,, 12]);
              tx = (_a = this.db) === null || _a === void 0 ? void 0 : _a.transaction(currentSequenceKey, 'readwrite');
              if (!tx) {
                return [2 /*return*/];
              }
              return [4 /*yield*/, tx.store.get(sessionId)];
            case 2:
              sequenceEvents = _b.sent();
              if (!!sequenceEvents) return [3 /*break*/, 4];
              return [4 /*yield*/, tx.store.put({
                sessionId: sessionId,
                events: [event]
              })];
            case 3:
              _b.sent();
              return [2 /*return*/];
            case 4:
              eventsToSend = void 0;
              if (!this.shouldSplitEventsList(sequenceEvents.events, event)) return [3 /*break*/, 6];
              eventsToSend = sequenceEvents.events;
              // set store to empty array
              return [4 /*yield*/, tx.store.put({
                sessionId: sessionId,
                events: [event]
              })];
            case 5:
              // set store to empty array
              _b.sent();
              return [3 /*break*/, 8];
            case 6:
              updatedEvents = sequenceEvents.events.concat(event);
              return [4 /*yield*/, tx.store.put({
                sessionId: sessionId,
                events: updatedEvents
              })];
            case 7:
              _b.sent();
              _b.label = 8;
            case 8:
              return [4 /*yield*/, tx.done];
            case 9:
              _b.sent();
              if (!eventsToSend) {
                return [2 /*return*/, undefined];
              }
              return [4 /*yield*/, this.storeSendingEvents(sessionId, eventsToSend)];
            case 10:
              sequenceId = _b.sent();
              if (!sequenceId) {
                return [2 /*return*/, undefined];
              }
              return [2 /*return*/, {
                events: eventsToSend,
                sessionId: sessionId,
                sequenceId: sequenceId
              }];
            case 11:
              e_3 = _b.sent();
              this.loggerProvider.warn("".concat(STORAGE_FAILURE, ": ").concat(e_3));
              return [3 /*break*/, 12];
            case 12:
              return [2 /*return*/, undefined];
          }
        });
      });
    };
    this.storeSendingEvents = function (sessionId, events) {
      return __awaiter(_this, void 0, void 0, function () {
        var sequenceId, e_4;
        var _a;
        return __generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              _b.trys.push([0, 2,, 3]);
              return [4 /*yield*/, (_a = this.db) === null || _a === void 0 ? void 0 : _a.put(sequencesToSendKey, {
                sessionId: sessionId,
                events: events
              })];
            case 1:
              sequenceId = _b.sent();
              return [2 /*return*/, sequenceId];
            case 2:
              e_4 = _b.sent();
              this.loggerProvider.warn("".concat(STORAGE_FAILURE, ": ").concat(e_4));
              return [3 /*break*/, 3];
            case 3:
              return [2 /*return*/, undefined];
          }
        });
      });
    };
    this.cleanUpSessionEventsStore = function (sequenceId) {
      return __awaiter(_this, void 0, void 0, function () {
        var e_5;
        var _a;
        return __generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              _b.trys.push([0, 2,, 3]);
              return [4 /*yield*/, (_a = this.db) === null || _a === void 0 ? void 0 : _a.delete(sequencesToSendKey, sequenceId)];
            case 1:
              _b.sent();
              return [3 /*break*/, 3];
            case 2:
              e_5 = _b.sent();
              this.loggerProvider.warn("".concat(STORAGE_FAILURE, ": ").concat(e_5));
              return [3 /*break*/, 3];
            case 3:
              return [2 /*return*/];
          }
        });
      });
    };
    this.transitionFromKeyValStore = function (sessionId) {
      return __awaiter(_this, void 0, void 0, function () {
        var keyValDb, transitionCurrentSessionSequences_1, storageKey, getAllRequest_1, transitionPromise, globalScope, e_6, e_7;
        var _this = this;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              _a.trys.push([0, 6,, 7]);
              return [4 /*yield*/, keyValDatabaseExists()];
            case 1:
              keyValDb = _a.sent();
              if (!keyValDb) {
                return [2 /*return*/];
              }
              transitionCurrentSessionSequences_1 = function (numericSessionId, sessionStore) {
                return __awaiter(_this, void 0, void 0, function () {
                  var currentSessionSequences, promisesToBatch;
                  var _this = this;
                  return __generator(this, function (_a) {
                    switch (_a.label) {
                      case 0:
                        currentSessionSequences = sessionStore.sessionSequences;
                        promisesToBatch = [];
                        Object.keys(currentSessionSequences).forEach(function (sequenceId) {
                          var numericSequenceId = parseInt(sequenceId, 10);
                          var sequence = currentSessionSequences[numericSequenceId];
                          if (numericSequenceId === sessionStore.currentSequenceId) {
                            var eventAddPromises = sequence.events.map(function (event) {
                              return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                  return [2 /*return*/, this.addEventToCurrentSequence(numericSessionId, event)];
                                });
                              });
                            });
                            promisesToBatch.concat(eventAddPromises);
                          } else if (sequence.status !== RecordingStatus.SENT) {
                            promisesToBatch.push(_this.storeSendingEvents(numericSessionId, sequence.events));
                          }
                        });
                        return [4 /*yield*/, batchPromiseAll(promisesToBatch)];
                      case 1:
                        _a.sent();
                        return [2 /*return*/];
                    }
                  });
                });
              };
              storageKey = "".concat(STORAGE_PREFIX, "_").concat(this.apiKey.substring(0, 10));
              _a.label = 2;
            case 2:
              _a.trys.push([2, 4,, 5]);
              getAllRequest_1 = keyValDb.transaction('keyval').objectStore('keyval').getAll(storageKey);
              transitionPromise = new Promise(function (resolve) {
                getAllRequest_1.onsuccess = function (e) {
                  return __awaiter(_this, void 0, void 0, function () {
                    var storedReplaySessionContextList, storedReplaySessionContexts, promisesToBatch_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                      switch (_a.label) {
                        case 0:
                          storedReplaySessionContextList = e && e.target.result;
                          storedReplaySessionContexts = storedReplaySessionContextList && storedReplaySessionContextList[0];
                          if (!storedReplaySessionContexts) return [3 /*break*/, 2];
                          promisesToBatch_1 = [];
                          Object.keys(storedReplaySessionContexts).forEach(function (storedSessionId) {
                            var numericSessionId = parseInt(storedSessionId, 10);
                            var oldSessionStore = storedReplaySessionContexts[numericSessionId];
                            if (sessionId === numericSessionId) {
                              promisesToBatch_1.push(transitionCurrentSessionSequences_1(numericSessionId, oldSessionStore));
                            } else {
                              var oldSessionSequences_1 = oldSessionStore.sessionSequences;
                              Object.keys(oldSessionSequences_1).forEach(function (sequenceId) {
                                var numericSequenceId = parseInt(sequenceId, 10);
                                if (oldSessionSequences_1[numericSequenceId].status !== RecordingStatus.SENT) {
                                  promisesToBatch_1.push(_this.storeSendingEvents(numericSessionId, oldSessionSequences_1[numericSequenceId].events));
                                }
                              });
                            }
                          });
                          return [4 /*yield*/, batchPromiseAll(promisesToBatch_1)];
                        case 1:
                          _a.sent();
                          _a.label = 2;
                        case 2:
                          resolve();
                          return [2 /*return*/];
                      }
                    });
                  });
                };
              });
              return [4 /*yield*/, transitionPromise];
            case 3:
              _a.sent();
              globalScope = getGlobalScope();
              if (globalScope) {
                globalScope.indexedDB.deleteDatabase('keyval-store');
              }
              return [3 /*break*/, 5];
            case 4:
              e_6 = _a.sent();
              this.loggerProvider.warn("Failed to transition session replay events from keyval to new store: ".concat(e_6));
              return [3 /*break*/, 5];
            case 5:
              return [3 /*break*/, 7];
            case 6:
              e_7 = _a.sent();
              this.loggerProvider.warn("Failed to access keyval store: ".concat(e_7, ". For more information, visit: https://www.docs.developers.amplitude.com/session-replay/sdks/standalone/#indexeddb-best-practices"));
              return [3 /*break*/, 7];
            case 7:
              return [2 /*return*/];
          }
        });
      });
    };
    this.loggerProvider = loggerProvider;
    this.apiKey = apiKey;
    this.maxInterval = maxInterval !== null && maxInterval !== void 0 ? maxInterval : MAX_INTERVAL;
    this.minInterval = minInterval !== null && minInterval !== void 0 ? minInterval : MIN_INTERVAL;
    this.interval = 0;
  }
  SessionReplayEventsIDBStore.prototype.initialize = function (type, sessionId) {
    return __awaiter(this, void 0, void 0, function () {
      var dbSuffix, dbName, _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            dbSuffix = type === 'replay' ? '' : "_".concat(type);
            dbName = "".concat(this.apiKey.substring(0, 10), "_amp_session_replay_events").concat(dbSuffix);
            _a = this;
            return [4 /*yield*/, createStore(dbName)];
          case 1:
            _a.db = _b.sent();
            this.timeAtLastSplit = Date.now(); // Initialize this so we have a point of comparison when events are recorded
            return [4 /*yield*/, this.transitionFromKeyValStore(sessionId)];
          case 2:
            _b.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  return SessionReplayEventsIDBStore;
}();
export { SessionReplayEventsIDBStore };
export var createEventsIDBStore = function (_a) {
  var loggerProvider = _a.loggerProvider,
    apiKey = _a.apiKey,
    sessionId = _a.sessionId,
    type = _a.type,
    minInterval = _a.minInterval,
    maxInterval = _a.maxInterval;
  return __awaiter(void 0, void 0, void 0, function () {
    var eventsIDBStore;
    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          eventsIDBStore = new SessionReplayEventsIDBStore({
            loggerProvider: loggerProvider,
            apiKey: apiKey,
            minInterval: minInterval,
            maxInterval: maxInterval
          });
          return [4 /*yield*/, eventsIDBStore.initialize(type, sessionId)];
        case 1:
          _b.sent();
          return [2 /*return*/, eventsIDBStore];
      }
    });
  });
};
