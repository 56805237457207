import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  NgbDropdown,
  NgbDropdownButtonItem,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle
} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {PrimeTemplate} from "primeng/api";
import {RouterLink} from "@angular/router";
import {ApplyFnModule} from "../../pipes/applyFn/apply-fn.module";
import {PluralSingularModule} from "../../pipes/pluralSingular/plural-singular.module";
import {AnimateOnScrollModule} from "primeng/animateonscroll";
import {ImageSizeModule} from "../../pipes/imageSize/image-size.module";
import {SkeletonModule} from "primeng/skeleton";
import {MultiselectListboxModule} from "../../form-elements/multiselect-listbox/multiselect-listbox.module";
import {ProductViewComponent} from "./product-view.component";
import {ProductDetailViewModule} from "../product-detail-view/product-detail-view.module";
import {PlaceholderImageModule} from "../../directives/placeholderImageDirective/placeholder-image.module";
import {ExternalLinkPopupModule} from "../external-link-popup/external-link-popup.module";
import {AffiliateUrlDirective} from "../../directives/affilateUrlDirective/affiliate-url.directive";


@NgModule({
  declarations: [ProductViewComponent],
    imports: [
        CommonModule,
        MultiselectListboxModule,
        NgbDropdown,
        NgbDropdownButtonItem,
        NgbDropdownItem,
        NgbDropdownMenu,
        NgbDropdownToggle,
        FormsModule,
        PrimeTemplate,
        RouterLink,
        PlaceholderImageModule,
        ProductDetailViewModule,
        ApplyFnModule,
        PluralSingularModule,
        AnimateOnScrollModule,
        ImageSizeModule,
        SkeletonModule,
        ExternalLinkPopupModule,
        AffiliateUrlDirective,
    ],
  exports:[
    ProductViewComponent
  ]
})
export class ProductViewModule { }
