import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageSizePipe} from "./image-size.pipe";


@NgModule({
  declarations: [ImageSizePipe],
  imports: [
    CommonModule
  ],
  exports: [
    ImageSizePipe
  ]
})
export class ImageSizeModule { }
