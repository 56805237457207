<section class="discover_product_listing">
  <div  class="mx-1140">
    <div class="discover_listing_inner">
      @if(isSpaceImages) {
        <h2 class="heading_2 discover_heading">More to discover</h2>
        <div class="right_sec arrow_btn">
          <button [disabled]="disableSlickButtons" [ngClass]="{'slick-disabled' : disableSlickButtons}"
            (click)="carousel.previousPage()" type="button" class="slick-prev slick-arrow" aria-disabled="true">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.60531 0.826294L1.52637 6.90524L7.60531 12.9842" stroke="black" stroke-width="1.51974"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </button>
          <button [disabled]="disableSlickButtons" [ngClass]="{'slick-disabled' : disableSlickButtons}"
            (click)="carousel.nextPage()" type="button" class="slick-next slick-arrow" aria-disabled="true">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.39469 0.826294L7.47363 6.90524L1.39469 12.9842" stroke="#222222" stroke-width="1.51974"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </button>
        </div>
<!--      <div class="navcard_mobile_space">-->
<!--        &lt;!&ndash; for All Products starts here&ndash;&gt;-->
<!--        @if(homepageService.PROPERTY_DETAILS?.showroomDetails?.allProductList?.productCount && pageType !== 'all_products') {-->
<!--          <div class="navbar_card">-->
<!--            <a class="navbar_card_link" routerLink="/{{footerService.PropertyId}}/all-products"></a>-->
<!--            <div class="navcard_left">-->
<!--              <img [src]="homepageService.PROPERTY_DETAILS?.showroomDetails?.allProductList?.products?.[0]?.image" alt="all_products">-->
<!--            </div>-->
<!--            <div class="navcard_right">-->
<!--              <div class="inner-content">-->
<!--                <p class="inner-text">All Products</p>-->
<!--                <div class="count_wrapper">-->
<!--                  <span class="inner_count">{{ homepageService.PROPERTY_DETAILS?.showroomDetails?.allProductList?.productCount }}</span>-->
<!--                </div>-->

<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        }-->
<!--        &lt;!&ndash; for Our Favorites starts here&ndash;&gt;-->
<!--        @if(homepageService.PROPERTY_DETAILS?.showroomDetails?.ourFavoriteProductList?.productCount && pageType !== 'our_favorites') {-->
<!--          <div class="navbar_card">-->
<!--            <a class="navbar_card_link" routerLink="/{{footerService.PropertyId}}/our-favorites"></a>-->
<!--            <div class="navcard_left">-->
<!--              <img [src]="homepageService.PROPERTY_DETAILS?.showroomDetails?.ourFavoriteProductList?.products?.[0]?.image" alt="our_favorites">-->
<!--              <span class="tag_ui fav_tag for_header"><i class="icon"><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M5.93662 0.321899C4.81916 0.321899 4.19059 1.25312 4.19059 1.71872C4.19059 1.25312 3.56202 0.321899 2.44455 0.321899C1.32709 0.321899 1.04773 1.25312 1.04773 1.71872C1.04773 4.16317 4.19059 5.9092 4.19059 5.9092C4.19059 5.9092 7.33344 4.16317 7.33344 1.71872C7.33344 1.25312 7.05408 0.321899 5.93662 0.321899Z" fill="white" stroke="white" stroke-width="0.609524" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  </svg></i>-->
<!--              </span>-->
<!--            </div>-->
<!--            <div class="navcard_right">-->
<!--              <div class="inner-content">-->
<!--                <p class="inner-text">Our Favorites</p>-->
<!--                <div class="count_wrapper">-->
<!--                  <span class="inner_count">{{ homepageService.PROPERTY_DETAILS?.showroomDetails?.ourFavoriteProductList?.productCount }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        }-->
<!--        &lt;!&ndash; for Local Items starts here&ndash;&gt;-->
<!--        @if(homepageService.PROPERTY_DETAILS?.showroomDetails?.localItemsProductList?.productCount && pageType !== 'local_items') {-->
<!--          <div class="navbar_card">-->
<!--            <a class="navbar_card_link" routerLink="/{{footerService.PropertyId}}/local-items"></a>-->
<!--            <div class="navcard_left">-->
<!--              <img [src]="homepageService.PROPERTY_DETAILS?.showroomDetails?.localItemsProductList?.products?.[0]?.image!" alt="local_items">-->
<!--              <span class="tag_ui fav_tag for_header">-->
<!--                <i class="icon">-->
<!--                  <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M7.42471 2.36281C7.13765 1.79652 6.71292 1.31153 6.18956 0.952407C5.66619 0.593287 5.061 0.37157 4.42962 0.307638C4.144 0.278964 3.85622 0.278964 3.5706 0.307638C2.93923 0.371612 2.33405 0.593323 1.81067 0.9524C1.28729 1.31148 0.862498 1.7964 0.575322 2.36262C0.214012 3.08508 0.0744528 3.89837 0.174223 4.70004C0.273992 5.50171 0.608629 6.25591 1.13598 6.86766L3.70757 9.99591C3.74308 10.0391 3.78773 10.0739 3.83829 10.0979C3.88886 10.1218 3.94409 10.1342 4.00002 10.1342C4.05594 10.1342 4.11117 10.1218 4.16174 10.0979C4.2123 10.0739 4.25695 10.0391 4.29246 9.99591L6.86405 6.86766C7.39139 6.25595 7.72603 5.50177 7.8258 4.70014C7.92557 3.8985 7.78601 3.08524 7.42471 2.36281ZM4.00002 5.58871C3.70052 5.58871 3.40775 5.49985 3.15873 5.33337C2.90971 5.16689 2.71562 4.93026 2.601 4.65341C2.48639 4.37656 2.4564 4.07192 2.51483 3.77802C2.57326 3.48411 2.71748 3.21415 2.92926 3.00225C3.14103 2.79036 3.41085 2.64606 3.70459 2.5876C3.99834 2.52914 4.30281 2.55914 4.5795 2.67382C4.8562 2.78849 5.0927 2.98269 5.25909 3.23185C5.42548 3.48101 5.51429 3.77394 5.51429 4.0736C5.51382 4.47529 5.35413 4.86039 5.07025 5.14443C4.78637 5.42846 4.40148 5.58824 4.00002 5.58871Z" fill="white"/>-->
<!--                  </svg>-->
<!--              </i>-->
<!--              </span>-->
<!--            </div>-->
<!--            <div class="navcard_right">-->
<!--              <div class="inner-content">-->
<!--                <p class="inner-text">Local Items</p>-->
<!--                <div class="count_wrapper">-->
<!--                  <span class="inner_count">{{ homepageService.PROPERTY_DETAILS?.showroomDetails?.localItemsProductList?.productCount }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        }-->
<!--      </div>-->
      <div class="discover_product_wrapper product-carousel_wrapper">
        <div class="ngx_carousel_wrapper" id="slick_favorite">
          <app-carousel #carousel [items]="spaceList | spaceFilter: currentSpaceId"
          [carouselConfig]="carouselConfig" appWheelThrottle (throttledWheel)="scrollCarousel($event, carousel)" [isDiscoverSpace]="true">
          <ng-template let-space #slide>

              <div class="slide">
                <div class="space_card" routerLink="/{{footerService.PropertyId}}/{{space.subspaceName}}">
                  <figure>
                    <img class="space_banner"
                         src="{{space.image!}}"
                         appPlaceholderImgDirective type="space"
                         srcset="
                                  {{space.image! | imageSize: '_thumb' }} 800w,
                                  {{space.image! | imageSize: '_hero_phone' }} 1200w,
                                  {{space.image! | imageSize: '_hero_phone' }} 1920w,
                                  {{space.image! | imageSize: '_hero_desk' }} 2560w
                                "
                         sizes="
                                  (max-width: 800px) 800px,
                                  (max-width: 1200px) 1200px,
                                  (max-width: 1920px) 1920px,
                                  2560px
                                "
                         alt="{{space.title}}"/>
                        <div class="item_count_wrapper">
                          <span class="tag_icons">
                              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.57747 13.5001C5.11284 13.5001 4.67578 13.319 4.347 12.9905L0.509625 9.15313C0.180844 8.82435 0 8.38729 0 7.92266C0 7.45804 0.181125 7.02098 0.509625 6.69219L6.69206 0.509757C7.09425 0.10757 7.66687 -0.0729932 8.22937 0.0271318L11.484 0.609882C12.1973 0.737569 12.7623 1.3026 12.89 2.01585L13.4727 5.27048C13.5728 5.83073 13.3926 6.40532 12.9901 6.80779L6.80794 12.9899C6.47916 13.3187 6.04209 13.4996 5.57747 13.4996V13.5001ZM7.92281 1.12513C7.76109 1.12513 7.60388 1.18898 7.48744 1.30541L1.30528 7.48757C1.06537 7.72748 1.06537 8.11785 1.30528 8.35776L5.14266 12.1951C5.38284 12.4353 5.77266 12.4353 6.01284 12.1951L12.195 6.01298C12.3373 5.87066 12.4009 5.66732 12.3654 5.46932L11.7827 2.21469C11.7377 1.96269 11.538 1.76301 11.2857 1.71773L8.03109 1.13498C7.99509 1.12851 7.95881 1.12541 7.92281 1.12541V1.12513Z" fill="#FCFCFC"/>
                                <path d="M9.84375 4.6875C10.3097 4.6875 10.6875 4.30974 10.6875 3.84375C10.6875 3.37776 10.3097 3 9.84375 3C9.37776 3 9 3.37776 9 3.84375C9 4.30974 9.37776 4.6875 9.84375 4.6875Z" fill="#FCFCFC"/>
                              </svg>
                            </span>
                          <span class="inner_counts">{{ space.productCount }}</span>
                        </div>
                  </figure>
                  <div class="space_content">
                    <p class="name_of_space">{{ space.title }}</p>
<!--                      <div class="count_wrapper">-->
<!--                        <span class="inner_count">{{ space.productCount }}</span>-->
<!--                      </div>-->
                  </div>
                </div>
              </div>

          </ng-template>

          </app-carousel>
        </div>


  <!--        <div class="row">-->
  <!--          @for (space of homepageService.PROPERTY_DETAILS?.spaceList; track space.id) {-->
  <!--            @if((!currentSpaceId && !!space.productCount) || (space?.subspaceName! !== currentSpaceId && !!space.productCount)) {-->
  <!--              <div class="col-6 col-sm-6 col-md-4 col-lg-3">-->
  <!--                <div class="space_card" routerLink="/{{footerService.PropertyId}}/{{space.subspaceName}}">-->
  <!--                  <figure>-->
  <!--                    <img class="space_banner" [src]="space.image"  alt="{{space.title}}"/>-->
  <!--                  </figure>-->
  <!--                  <div class="space_content">-->
  <!--                    <p class="name_of_space">{{ space.title }}</p>-->
  <!--                    <div class="count_wrapper">-->
  <!--                      <span class="inner_count">{{ space.productCount }}</span>-->
  <!--                    </div>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            }-->
  <!--          }-->
  <!--        </div>-->
        </div>
      }
      <div class="btn_wrapper view_product_btn">
        @if(pageType !== 'all_products') {
          <div class="btn_blk">
            <a routerLink="/{{footerService.PropertyId}}/all-products" class="button_primary" (click)="blurButton($event); amplitudeService.viewAllProductsClick(pageType)">View All Products</a>
          </div>
        }
        <p class="text_link2">When you purchase an item on this page, Minoan and the property may earn a commission.
          By clicking a product link, you agree to our <a target="_blank" href="https://minoan.com/terms">Terms</a> and <a target="_blank" href="https://minoan.com/privacy">Privacy Policy</a>.</p>
      </div>

    </div>
  </div>
</section>
