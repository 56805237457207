import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoBrandingComponent} from "./co-branding.component";
import {ApplyFnModule} from "../../pipes/applyFn/apply-fn.module";
import {ImageSizeModule} from "../../pipes/imageSize/image-size.module";


@NgModule({
  declarations: [CoBrandingComponent],
  imports: [
    CommonModule,
    ApplyFnModule,
    ImageSizeModule
  ],
  exports: [CoBrandingComponent]
})
export class CoBrandingModule { }
