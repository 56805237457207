<section class="product-listing">
  <!-- Mobile Page title and dropdown -->
  <div class="mobile_title_dropdown"  [ngClass]="{
  'scrolling_top': footerService.isScrollingTopUpdated && isScrollingTop,
  'scrolling_bottom': footerService.isScrollingTopUpdated && !isScrollingTop,

}">
    <button type="button" class="btn custom_dropdown_btn" (click)="menuBtnClick()">
      {{ spaceDetails?.title?.length! <= 48 ? spaceDetails?.title! : spaceDetails?.title?.substr(0, 45)+'...' }}<span class="icon"><svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0.999896L6.5 6.21484L12 0.999896" stroke="black" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      </span>
    </button>
  </div>
  <div class="mx-1140">
    <!-- Product List Viewer   -->
    <div class="product_header">
      <div class="top_wrapper">
        <div class="left_sec">
          <p>{{ displayedProducts?.length! }} {{ displayedProducts?.length! | pluralSingular: 'item' : 'items' }}</p>
        </div>
        <div class="right_sec">
          <!-- <button class="brand">kkl</button>-->
          <!--Brand Filter-->
          <div ngbDropdown class="filter_dropdown_sec userlist_dropdown dropdown " (openChange)="onChangeDropdown($event, quoteBrandsDropdown)" #quoteBrandsDropdown="ngbDropdown">
            <button type="button" class="btn dropdown_btn" id="brandfilter" ngbDropdownToggle (click)="onBrandsDropdownButtonClick($event)" ><!--  [disabled]="emptyFilter"-->
<!--              <span class="icon">-->
<!--                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M15.9609 8.37868V3.75C15.9609 2.92157 15.2894 2.25 14.4609 2.25H9.83226C9.43443 2.25 9.0529 2.40804 8.7716 2.68934L2.7716 8.68934C2.18581 9.27513 2.18581 10.2249 2.7716 10.8107L7.40028 15.4393C7.98606 16.0251 8.93581 16.0251 9.5216 15.4393L15.5216 9.43934C15.8029 9.15804 15.9609 8.7765 15.9609 8.37868Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                      <path d="M12.9609 5.25H12.9599" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                      </svg>-->
<!--                 </span>-->
              <p class="el-text">Brand</p>
              <span class="caret-icon">
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.25195 1.31972L5.29775 5.27393L1.34354 1.31972" stroke="black" stroke-width="0.988551" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
            </button>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="brandfilter">
              @if(quoteBrandsDropdown.isOpen()) {
                <div class="dropdown_listing">
                  <app-multiselect-listbox
                    [(ngModel)]="selectedBrands"
                    [options]="brandList"
                    filterPlaceHolder="Search brand name…"
                    emptyFilterMessage="No matches found."
                    (enterClickedOnItem)="enterClickedOnItemHandler()"
                    [metaKeySelection]="false"
                    [checkbox]="true"
                    [filter]="true"
                    [multiple]="true"
                    [listStyle]="{'max-height':'190px', 'min-height':'190px'}"
                    [style]="{'width':'296px'}"
                    [(dropdownClose)]="quoteBrandsDropdown._open">
                    <ng-template let-country pTemplate="item">
                      <div class="country-item">
                        <div class="quote_member_name">{{country}}</div>
                      </div>
                    </ng-template>
                  </app-multiselect-listbox>
                  <div class="btn_wrapper_dropdown">
                    <button class="btn btn_primary" id="apply-brand-status" [disabled]="isEqualBrandObject" (click)="applyFilterToUrl('brandList',quoteBrandsDropdown)">Apply</button>
                  </div>
                </div>
              }
            </div>
          </div>
          <!--End Brand Filter-->
        </div>
      </div>
      @if(selectedBrandBadges.length) {
        <div class="selected_filter_wrap">
          <div class="select_filter_list">
            <ul>
              @for (brand of selectedBrandBadges; track brand) {
                <li id="remove-brand-{{$index}}" (click)="removeOnClose($index)">
                  {{brand}}
                  <span class="cross_btn" >
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.98591 1.66663L1.93555 8.33329M1.93555 1.66663L8.98591 8.33329" stroke="#222222" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                </li>
              }
            </ul>
          </div>
          <div class="cf_btn_wrap">
            <button class="cf_btn" id="clearAll" (click)="clearAllFilters()">
              Clear All
              <i class="icon">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.33366 1.66663L1.66699 8.33329M1.66699 1.66663L8.33366 8.33329" stroke="#222222" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
            </button>
          </div>
        </div>
      }
    </div>
    <div class="container">
      <div class="row">
        @if(displayedProducts?.length == 0 && productList.length) {
          <div class="col-md-12">
            <div class="empty_message_sec">
              <figure> <img src="assets/images/no_result_empty.svg" alt="" /> </figure>
              <h3 class="empty_msg_title">Sorry, we couldn’t find any results.</h3>
              <p class="empty_desc">No products match the filter criteria.</p>
              <div class="add_item_btn_wrap">
                <a href="javascript:void(0);" class="link-primary-btn">
             <span class="el-icon">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.33366 1.66663L1.66699 8.33329M1.66699 1.66663L8.33366 8.33329" stroke="#222222" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
             </span>
                  <p class="el-text">Clear All Filters</p>
                </a>
              </div>
            </div>
          </div>
        } @else {
          @for (product of displayedProducts; track product.id) {
            <div class="col-6 col-sm-6 col-md-4 col-lg-3">
              @defer (on viewport) {
                <div class="product_card">
                  <a appAffiliateUrl [product]="product" [isMobile]="isMobile" href="{{product.affiliateUrl ? (product.affiliateUrl | applyFn: getWebLink) : (product.productUrl | applyFn: getWebLink)}}"
                     id="{{product.showroomProductName}}" target="_blank" (click)="openProduct($event, $index)" class="hover_link"></a>
                  <div class="product_img_wrapper">
                    @if (product.isFavourite && spaceDetails?.subspaceId !== 'our_favorite') {
                      <span class="tag_ui fav_tag">
                    <i class="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                        <path d="M5.9365 1.70654C4.81904 1.70654 4.19046 2.63776 4.19046 3.10337C4.19046 2.63776 3.56189 1.70654 2.44443 1.70654C1.32697 1.70654 1.04761 2.63776 1.04761 3.10337C1.04761 5.54781 4.19046 7.29384 4.19046 7.29384C4.19046 7.29384 7.33332 5.54781 7.33332 3.10337C7.33332 2.63776 7.05396 1.70654 5.9365 1.70654Z" fill="white" stroke="white" stroke-width="0.609524" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </i>
                    Our Favorites
                  </span>
                    }

                    @if (product.isLocal && spaceDetails?.subspaceId !== 'local_items') {
                      <span class="tag_ui local_tag">
                    <i class="icon">
                      <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.69168 1.5464C5.48702 1.12471 5.1842 0.76356 4.81106 0.496141C4.43793 0.228723 4.00645 0.0636207 3.55629 0.0160138C3.35266 -0.00533793 3.14749 -0.00533793 2.94385 0.0160138C2.4937 0.0636519 2.06223 0.228749 1.68908 0.496136C1.31593 0.763523 1.01307 1.12462 0.808324 1.54626C0.550723 2.08424 0.451222 2.68985 0.522355 3.28682C0.593487 3.88378 0.83207 4.4454 1.20805 4.90093L3.0415 7.23039C3.06682 7.26257 3.09865 7.2885 3.1347 7.3063C3.17075 7.3241 3.21013 7.33333 3.25 7.33333C3.28988 7.33333 3.32925 7.3241 3.3653 7.3063C3.40136 7.2885 3.43319 7.26257 3.45851 7.23039L5.29195 4.90093C5.66793 4.44542 5.90651 3.88383 5.97765 3.28689C6.04878 2.68995 5.94928 2.08436 5.69168 1.5464ZM3.25 3.94857C3.03647 3.94857 2.82774 3.8824 2.6502 3.75843C2.47265 3.63445 2.33427 3.45825 2.25256 3.25209C2.17085 3.04594 2.14947 2.81909 2.19112 2.60023C2.23278 2.38138 2.33561 2.18035 2.48659 2.02256C2.63758 1.86477 2.82995 1.75732 3.03938 1.71379C3.24881 1.67026 3.46588 1.6926 3.66316 1.77799C3.86043 1.86338 4.02905 2.00799 4.14768 2.19353C4.26631 2.37906 4.32963 2.5972 4.32963 2.82034C4.32929 3.11946 4.21544 3.40622 4.01304 3.61773C3.81065 3.82924 3.53623 3.94822 3.25 3.94857Z" fill="white"/>
                      </svg>
                    </i>
                    Local
                  </span>
                    }
                    <img src="{{product.image!}}"
                         srcset="
                                  {{product.image! }} 3840w,
                                  {{product.image! | imageSize: '_hero_phone' }} 1920w,
                                  {{product.image! | imageSize: '_hero_phone' }} 1200w,
                                  {{product.image! | imageSize: '_thumb' }} 800w
                                "
                         sizes="
                                (min-width: 3840px) 3840px,
                                (min-width: 1920px) 1920px,
                                (min-width: 1200px) 1200px,
                                800px
                              "
                         appPlaceholderImgDirective alt="{{product.productName}}" width="100%">
                    <button class="shop_now_btn button_primary">Shop Now</button>
                  </div>
                  <div class="product_desc">
                    @if(product.brandName) {
                      <label class="para1">{{ product.brandName }}</label>
                    }
                    <p class="para1">{{ product.productName }}</p>
                  </div>
                </div>
              } @placeholder (minimum 100ms) {
                <div class="product_card">
                  <p-skeleton height="282px" width="100%"></p-skeleton>
                </div>
              }
            </div>
          }
        }
      </div>
    </div>
  </div>
</section>
<app-product-detail-view [productList]="displayedProducts" [(productIndex)]="productIndex" [(showProductModal)]="showProductModal" [previousURL]="spaceDetails?.subspaceName ? spaceDetails?.subspaceName! : spaceDetails?.subspaceId!"></app-product-detail-view>


<app-external-link-popup [(externalLink)]="externalLinkPopup"></app-external-link-popup>
