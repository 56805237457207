import {CanActivateFn, Router} from '@angular/router';
import {inject, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";
import {HomepageService} from "../services/homepage/homepage.service";
import {productFinderFunction} from "../utils/common-function";

/**
 * Guard to remove product id from URL on desktop
 * @param route Activated Route Snapshot
 * @param state Current Router State
 */
export const mobileDeviceGuard: CanActivateFn = async (route, state) => {
  const queryParams = route.queryParams;
  const platformId = inject(PLATFORM_ID)
  const isBrowser = isPlatformBrowser(platformId)
  const router: Router = inject(Router);
  const homepageService = inject(HomepageService);
  const propertyId =  state.url.split('/').filter(each => !!each)[0];

  if(!homepageService.PROPERTY_DETAILS) {
    await homepageService.fetchPageInformation(route.params['propertyId'] ? route.params['propertyId'] : propertyId);
  }
  const previousUrl = route.params['propertyId'] ? `/${route.params['propertyId']}` : route.params['spaceId'] ? `/${propertyId}/${route.params['spaceId']}` : `/${propertyId}/${route.routeConfig?.path!}`;
  // return true

  if(isBrowser) {
    if(queryParams['productId']) {
      const product = homepageService.PROPERTY_DETAILS?.showroomDetails.allProductList.products.find(each => productFinderFunction(each, queryParams['productId']))
      if(product && (product.affiliateUrl || product.productUrl) && window.innerWidth > 767) {
        homepageService.CLICK_PRODUCT_ID.next(product.showroomProductName)
      }
      return window.innerWidth <= 767 ? true : router.parseUrl(previousUrl);
    }
    return true;
  }
  return true;
};
