<p-dialog [(visible)]="externalLink" draggable="false" styleClass="external_link_wrapper" (onHide)="onHideExternalLinkModal()">
  <div class="modal-content">
    <i class="link_icon">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1898_33923)">
          <path d="M28.7028 19.3051L31.6287 17.6158C33.5793 16.4896 38.1562 15.4077 40.859 20.0891C43.5618 24.7705 40.3363 28.1931 38.3857 29.3193L35.4599 31.0086" stroke="#96999F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.9222 24.3727L16.9963 26.0619C15.0457 27.1881 11.8202 30.6108 14.523 35.2922C17.2259 39.9736 21.8027 38.8916 23.7533 37.7654L26.6792 36.0762" stroke="#96999F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M21.8398 31.0693L33.5433 24.3123" stroke="#96999F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_1898_33923">
            <rect width="40.5421" height="40.5421" fill="white" transform="translate(0 20.271) rotate(-30)"/>
          </clipPath>
        </defs>
      </svg>
    </i>
      <p class="desc">
        You have clicked a link to an external website. All external links will open in a new browser tab.
      </p>
      <button class="link-primary-btn" (click)="externalLink = false">
        <i class="icon">
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 7H1M1 7L7 13M1 7L7 1" stroke="#DC5A4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg></i>Back to my Showroom</button>
  </div>

</p-dialog>
