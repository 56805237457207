import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WheelThrottleDirective} from "./wheel-throttle.directive";


@NgModule({
  declarations: [WheelThrottleDirective],
  imports: [
    CommonModule
  ], exports :[WheelThrottleDirective]
})
export class WheelThrottleModule { }
