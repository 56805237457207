import { __assign } from "tslib";
/**
 * Returns false if defaultTracking === false or if defaultTracking[event],
 * otherwise returns true
 */
var isTrackingEnabled = function (defaultTracking, event) {
  if (typeof defaultTracking === 'boolean') {
    return defaultTracking;
  }
  if ((defaultTracking === null || defaultTracking === void 0 ? void 0 : defaultTracking[event]) === false) {
    return false;
  }
  return true;
};
export var isAttributionTrackingEnabled = function (defaultTracking) {
  return isTrackingEnabled(defaultTracking, 'attribution');
};
export var isFileDownloadTrackingEnabled = function (defaultTracking) {
  return isTrackingEnabled(defaultTracking, 'fileDownloads');
};
export var isFormInteractionTrackingEnabled = function (defaultTracking) {
  return isTrackingEnabled(defaultTracking, 'formInteractions');
};
export var isPageViewTrackingEnabled = function (defaultTracking) {
  return isTrackingEnabled(defaultTracking, 'pageViews');
};
export var isSessionTrackingEnabled = function (defaultTracking) {
  return isTrackingEnabled(defaultTracking, 'sessions');
};
export var getPageViewTrackingConfig = function (config) {
  var trackOn = function () {
    return false;
  };
  var trackHistoryChanges = undefined;
  var eventType;
  var pageCounter = config.pageCounter;
  var isDefaultPageViewTrackingEnabled = isPageViewTrackingEnabled(config.defaultTracking);
  if (isDefaultPageViewTrackingEnabled) {
    trackOn = undefined;
    eventType = undefined;
    if (config.defaultTracking && typeof config.defaultTracking === 'object' && config.defaultTracking.pageViews && typeof config.defaultTracking.pageViews === 'object') {
      if ('trackOn' in config.defaultTracking.pageViews) {
        trackOn = config.defaultTracking.pageViews.trackOn;
      }
      if ('trackHistoryChanges' in config.defaultTracking.pageViews) {
        trackHistoryChanges = config.defaultTracking.pageViews.trackHistoryChanges;
      }
      if ('eventType' in config.defaultTracking.pageViews && config.defaultTracking.pageViews.eventType) {
        eventType = config.defaultTracking.pageViews.eventType;
      }
    }
  }
  return {
    trackOn: trackOn,
    trackHistoryChanges: trackHistoryChanges,
    eventType: eventType,
    pageCounter: pageCounter
  };
};
export var getAttributionTrackingConfig = function (config) {
  if (isAttributionTrackingEnabled(config.defaultTracking) && config.defaultTracking && typeof config.defaultTracking === 'object' && config.defaultTracking.attribution && typeof config.defaultTracking.attribution === 'object') {
    return __assign({}, config.defaultTracking.attribution);
  }
  return {};
};
