import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'applyFn'
})
export class ApplyFnPipe implements PipeTransform {
  transform(value: any, functionToBeApplied: Function, extraArgs: any[] = []): unknown {
    return functionToBeApplied(value, ...extraArgs);
  }
}
