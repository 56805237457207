<footer class="footer_wrapper">
  <div class="mx-1660">
    <div class="top_footer_sec">
      <div class="footer_left_sec">
        <div class="footer_left_inner">
          <div class="subscribe_wrapper" [class.formSubmitted]="isSuccess">
            <h3 class="heading_text">Keep in Touch</h3>
            <label>Minoan is creating an entirely new way to shop. <br/>Enter your email to follow us on our journey.</label>
            <form class="form-group" [formGroup]="subscriptionForm" (submit)="executeRecaptcha()">
              <input #myInput formControlName="email" [placeholder]="placeholderText" (input)="reEnterEmail()" (focusin)="focusInEvent()" (focusout)="focusOutEvent()" type="text" inputmode="email" autocapitalize="none" #stayTouchInputElem/>
              <button class="submit_btn" type="submit" [class.disabled]="isFormSubmitted && newsletterFormControl.email.hasError('pattern')">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="11" cy="11" r="10.5" stroke="white"/>
                  <path d="M6.41634 11.0003H15.583M15.583 11.0003L11.183 6.41699M15.583 11.0003L11.183 15.5837"
                        stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
              </button>
              <span class="success_icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M4.58301 11.917L8.24967 15.5837L17.4163 6.41699" stroke="white" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              @if(showRecaptcha) {
                <ngx-invisible-recaptcha #captchaElem
                                         siteKey="6LcLu6shAAAAAFzq_QE1xRgPHBXtmvmO48vVw9Bb"
                                         (success)="handleSuccess($event)"
                                         [useGlobalDomain]="false"
                >
                </ngx-invisible-recaptcha>
              }
            </form>
            <!-- <p class="subscribe_info">Learn more about how we protect your personal data by viewing our <a href="javascript:;" class="link_ui">Privacy Policy.</a></p>-->
            @if(newsletterFormControl.email.invalid && isFormSubmitted) {
              @if(newsletterFormControl.email.hasError('pattern') || newsletterFormControl.email.hasError('required')) {
                <div class="error_ui">Invalid email address</div>
              }
            }
            <p class="success_msg">Thank you! You are now subscribed to our newsletter.</p>
          </div>
        </div>
<!--        <div class="footer_nav_sec">-->
<!--          <div class="service_manager">-->
<!--            <p class="inner-text">For Hosts, Hotels, and Property Managers</p>-->
<!--            <a class="shop_property" target="_blank" href="https://minoan.com" (click)="footerClickEventTracker('make_my_property_shoppable')">Make my property shoppable</a>-->
<!--          </div>-->
<!--          <div class="service_manager">-->
<!--            <p class="inner-text">For Brands</p>-->
<!--            <a class="shop_property" target="_blank" href="https://minoan.com" (click)="footerClickEventTracker('partner_with_minoan')">Partner with Minoan</a>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <div class="bottom_footer_sec">
      <div class="left_sec">
        <a class="footer_logo">
          <img src="assets/images/white_minoan_logo.svg" alt="logo"/>
        </a>
        <div class="heading-bottom_left_inner">
          <a class="minoan_url desk_show" target="_blank" href="https://minoan.com/">www.minoan.com</a>
          <ul class="bottom_link">
            <li>
              <p class="copyright desk_show">©{{today.getFullYear()}} Minoan Experience.</p>
            </li>
            <li>
              <a  target="_blank" href="https://minoan.com/terms" (click)="footerClickEventTracker('terms_and_conditions')">Terms & Conditions</a>
            </li>
            <li>
              <a  target="_blank" href="https://minoan.com/privacy" (click)="footerClickEventTracker('privacy_policy')">Privacy Policy</a>
            </li>
          </ul>
        </div>
      </div>
      <a class="minoan_url mob_show" target="_blank" href="https://minoan.com/">www.minoan.com</a>
      <p class="copyright mob_show">©{{today.getFullYear()}} Minoan Experience.</p>

    </div>
  </div>
</footer>
