export var UTM_CAMPAIGN = 'utm_campaign';
export var UTM_CONTENT = 'utm_content';
export var UTM_ID = 'utm_id';
export var UTM_MEDIUM = 'utm_medium';
export var UTM_SOURCE = 'utm_source';
export var UTM_TERM = 'utm_term';
export var DCLID = 'dclid';
export var FBCLID = 'fbclid';
export var GBRAID = 'gbraid';
export var GCLID = 'gclid';
export var KO_CLICK_ID = 'ko_click_id';
export var LI_FAT_ID = 'li_fat_id';
export var MSCLKID = 'msclkid';
export var RDT_CID = 'rtd_cid';
export var TTCLID = 'ttclid';
export var TWCLID = 'twclid';
export var WBRAID = 'wbraid';
export var EMPTY_VALUE = 'EMPTY';
export var BASE_CAMPAIGN = {
  utm_campaign: undefined,
  utm_content: undefined,
  utm_id: undefined,
  utm_medium: undefined,
  utm_source: undefined,
  utm_term: undefined,
  referrer: undefined,
  referring_domain: undefined,
  dclid: undefined,
  gbraid: undefined,
  gclid: undefined,
  fbclid: undefined,
  ko_click_id: undefined,
  li_fat_id: undefined,
  msclkid: undefined,
  rtd_cid: undefined,
  ttclid: undefined,
  twclid: undefined,
  wbraid: undefined
};
export var MKTG = 'MKTG';
