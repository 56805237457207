const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

const FORM_ERRORS : any = {
  EMAIL_REQUIRED: 'Email is Required.',
  FIELD_REQUIRED: 'This field is required.',
  EMAIL_INVALID : 'Email is invalid.',
  URL_INVALID : 'URL is invalid.',
  ATLEAST_ONE_REQUIRED: 'Atleast one value should be selected.',
}

const PLACEHOLDER_URL = "https://storage.googleapis.com/minoan-images/minoan%20website%20redesign/product_placeholder.jpg";
const SPACE_PLACEHOLDER = "assets/images/space_placeholder.jpg";

export {
  EMAIL_REGEX,
  FORM_ERRORS,
  PLACEHOLDER_URL,
  SPACE_PLACEHOLDER,
}


