import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import { deleteDB, openDB } from 'idb';
var MAX_IDB_STORAGE_TIME = 1000 * 60 * 60 * 24 * 3; // 3 days
export var openOrCreateRemoteConfigStore = function (dbName, configKeys) {
  return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4 /*yield*/, openDB(dbName, 1, {
            upgrade: function (db) {
              configKeys.forEach(function (key) {
                if (!db.objectStoreNames.contains(key)) {
                  db.createObjectStore(key);
                }
              });
            }
          })];
        case 1:
          return [2 /*return*/, _a.sent()];
      }
    });
  });
};
export var openOrCreateMetaStore = function (dbName) {
  return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4 /*yield*/, openDB(dbName, 1, {
            upgrade: function (db) {
              if (!db.objectStoreNames.contains('lastFetchedSessionId')) {
                db.createObjectStore('lastFetchedSessionId');
              }
            }
          })];
        case 1:
          return [2 /*return*/, _a.sent()];
      }
    });
  });
};
export var createRemoteConfigIDBStore = function (_a) {
  var loggerProvider = _a.loggerProvider,
    apiKey = _a.apiKey,
    configKeys = _a.configKeys;
  return __awaiter(void 0, void 0, void 0, function () {
    var remoteConfigDBName, remoteConfigDB, metaDBName, metaDB, lastFetchedSessionId, e_1, storeRemoteConfig, getLastFetchedSessionId, getRemoteConfig, remoteConfigHasValues;
    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          remoteConfigDBName = "".concat(apiKey.substring(0, 10), "_amp_config");
          return [4 /*yield*/, openOrCreateRemoteConfigStore(remoteConfigDBName, configKeys)];
        case 1:
          remoteConfigDB = _b.sent();
          metaDBName = "".concat(apiKey.substring(0, 10), "_amp_config_meta");
          return [4 /*yield*/, openOrCreateMetaStore(metaDBName)];
        case 2:
          metaDB = _b.sent();
          _b.label = 3;
        case 3:
          _b.trys.push([3, 8,, 9]);
          return [4 /*yield*/, metaDB.get('lastFetchedSessionId', 'sessionId')];
        case 4:
          lastFetchedSessionId = _b.sent();
          if (!(lastFetchedSessionId && Date.now() - lastFetchedSessionId >= MAX_IDB_STORAGE_TIME)) return [3 /*break*/, 7];
          remoteConfigDB.close();
          return [4 /*yield*/, deleteDB(remoteConfigDBName)];
        case 5:
          _b.sent();
          return [4 /*yield*/, openOrCreateRemoteConfigStore(remoteConfigDBName, configKeys)];
        case 6:
          remoteConfigDB = _b.sent();
          _b.label = 7;
        case 7:
          return [3 /*break*/, 9];
        case 8:
          e_1 = _b.sent();
          loggerProvider.warn("Failed to reset store: ".concat(e_1));
          return [3 /*break*/, 9];
        case 9:
          storeRemoteConfig = function (remoteConfig, sessionId) {
            return __awaiter(void 0, void 0, void 0, function () {
              var _loop_1, _a, _b, _c, _i, configNamespace, e_2;
              return __generator(this, function (_d) {
                switch (_d.label) {
                  case 0:
                    _d.trys.push([0, 7,, 8]);
                    if (!sessionId) return [3 /*break*/, 2];
                    return [4 /*yield*/, metaDB.put('lastFetchedSessionId', sessionId, 'sessionId')];
                  case 1:
                    _d.sent();
                    _d.label = 2;
                  case 2:
                    _loop_1 = function (configNamespace) {
                      var config, tx;
                      return __generator(this, function (_e) {
                        switch (_e.label) {
                          case 0:
                            config = remoteConfig.configs[configNamespace];
                            tx = remoteConfigDB.transaction(configNamespace, 'readwrite');
                            return [4 /*yield*/, Promise.all(__spreadArray(__spreadArray([], __read(Object.keys(config).map(function (key) {
                              return tx.store.put(__assign({}, config[key]), key);
                            })), false), [tx.done], false))];
                          case 1:
                            _e.sent();
                            return [2 /*return*/];
                        }
                      });
                    };
                    _a = remoteConfig.configs;
                    _b = [];
                    for (_c in _a) _b.push(_c);
                    _i = 0;
                    _d.label = 3;
                  case 3:
                    if (!(_i < _b.length)) return [3 /*break*/, 6];
                    _c = _b[_i];
                    if (!(_c in _a)) return [3 /*break*/, 5];
                    configNamespace = _c;
                    return [5 /*yield**/, _loop_1(configNamespace)];
                  case 4:
                    _d.sent();
                    _d.label = 5;
                  case 5:
                    _i++;
                    return [3 /*break*/, 3];
                  case 6:
                    return [3 /*break*/, 8];
                  case 7:
                    e_2 = _d.sent();
                    loggerProvider.warn("Failed to store remote config: ".concat(e_2));
                    return [3 /*break*/, 8];
                  case 8:
                    return [2 /*return*/];
                }
              });
            });
          };
          getLastFetchedSessionId = function () {
            return __awaiter(void 0, void 0, void 0, function () {
              var lastFetchedSessionId, e_3;
              return __generator(this, function (_a) {
                switch (_a.label) {
                  case 0:
                    _a.trys.push([0, 2,, 3]);
                    return [4 /*yield*/, metaDB.get('lastFetchedSessionId', 'sessionId')];
                  case 1:
                    lastFetchedSessionId = _a.sent();
                    return [2 /*return*/, lastFetchedSessionId];
                  case 2:
                    e_3 = _a.sent();
                    loggerProvider.warn("Failed to fetch lastFetchedSessionId: ".concat(e_3));
                    return [3 /*break*/, 3];
                  case 3:
                    return [2 /*return*/, undefined];
                }
              });
            });
          };
          getRemoteConfig = function (configNamespace, key) {
            return __awaiter(void 0, void 0, void 0, function () {
              var config, e_4;
              return __generator(this, function (_a) {
                switch (_a.label) {
                  case 0:
                    _a.trys.push([0, 2,, 3]);
                    return [4 /*yield*/, remoteConfigDB.get(configNamespace, key)];
                  case 1:
                    config = _a.sent();
                    return [2 /*return*/, config];
                  case 2:
                    e_4 = _a.sent();
                    loggerProvider.warn("Failed to fetch remote config: ".concat(e_4));
                    return [3 /*break*/, 3];
                  case 3:
                    return [2 /*return*/, undefined];
                }
              });
            });
          };
          remoteConfigHasValues = function (configNamespace) {
            return __awaiter(void 0, void 0, void 0, function () {
              var config, e_5;
              return __generator(this, function (_a) {
                switch (_a.label) {
                  case 0:
                    _a.trys.push([0, 2,, 3]);
                    return [4 /*yield*/, remoteConfigDB.getAll(configNamespace)];
                  case 1:
                    config = _a.sent();
                    return [2 /*return*/, !!config.length];
                  case 2:
                    e_5 = _a.sent();
                    loggerProvider.warn("Failed to fetch remote config: ".concat(e_5));
                    return [3 /*break*/, 3];
                  case 3:
                    return [2 /*return*/, false];
                }
              });
            });
          };
          return [2 /*return*/, {
            storeRemoteConfig: storeRemoteConfig,
            getRemoteConfig: getRemoteConfig,
            getLastFetchedSessionId: getLastFetchedSessionId,
            remoteConfigHasValues: remoteConfigHasValues
          }];
      }
    });
  });
};
