/* eslint-disable @typescript-eslint/unbound-method */
import client from './browser-client-factory';
export { createInstance } from './browser-client-factory';
export var add = client.add,
  extendSession = client.extendSession,
  flush = client.flush,
  getDeviceId = client.getDeviceId,
  getSessionId = client.getSessionId,
  getUserId = client.getUserId,
  groupIdentify = client.groupIdentify,
  identify = client.identify,
  init = client.init,
  logEvent = client.logEvent,
  remove = client.remove,
  reset = client.reset,
  revenue = client.revenue,
  setDeviceId = client.setDeviceId,
  setGroup = client.setGroup,
  setOptOut = client.setOptOut,
  setSessionId = client.setSessionId,
  setTransport = client.setTransport,
  setUserId = client.setUserId,
  track = client.track;
export { runQueuedFunctions } from './utils/snippet-helper';
export { Revenue, Identify } from '@amplitude/analytics-core';
import * as Types_1 from '@amplitude/analytics-types';
export { Types_1 as Types };
