import {ViewportRuler} from '@angular/cdk/scrolling';
import {NgClass, NgTemplateOutlet} from '@angular/common';
import {
  afterNextRender,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import KeenSlider, {KeenSliderInstance, KeenSliderOptions} from 'keen-slider';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [NgTemplateOutlet, NgClass],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss'
})
export class CarouselComponent implements OnDestroy {


  @Input({transform: (value: any[] | undefined) => {
    if(value && value.length <= 8 && value.length >= 5) {
      return [...value, ...value];
    }
    return value;
    }}) items !: any[] | undefined;
  @Input() idKey !: string;
  @Input() carouselConfig !: KeenSliderOptions;
  @Input() isDiscoverSpace : boolean = false;
  @ContentChild('slide') slide !: TemplateRef<any> | null;
  @Output() slideChanged = new EventEmitter<void>();

  @ViewChild("sliderRef", { static: false }) sliderRef!: ElementRef<HTMLElement>;

  slider!: KeenSliderInstance;
  viewPortObserverSubscription!: Subscription;
  touchStartX!: number;
  touchEndX!: number;

  slidesToScroll: number = 2;


  constructor(private viewportRuler: ViewportRuler,
    private ngZone: NgZone) {
    afterNextRender(() => {
      this.observeViewPortChanges();
      this.ngZone.run(() => {
        this.isDiscoverSpace ?  this.updateSliderForSpace() : this.updateSlider();
      });
    })
  }

  initializeSlider(config: KeenSliderOptions) {
    if (this.sliderRef) {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, config);
      this.slider.on('animationEnded', () => {
         this.slideChanged.emit();
      })
    }
  }

  public nextPage() {
    const currentIndex = this.slider.track.details.rel;
    let targetIndex = currentIndex + this.slidesToScroll;
    if (this.slider.options.loop) {
      this.slider.moveToIdx(targetIndex);
    } else if (targetIndex > (this.items?.length as number - this.slidesToScroll) && !this.slider.options.loop) {
      this.slider.update(this.slider.options, this.items?.length as number - 1);
    } else {
      this.slider.moveToIdx(targetIndex);
    }
  }



  public previousPage() {
    const currentIndex = this.slider.track.details.rel;
    const targetIndex = currentIndex - this.slidesToScroll;

    if (this.slider.options.loop) {
      this.slider.moveToIdx(targetIndex);
    }
    else if (targetIndex < 0 && !this.slider.options.loop) {
      this.slider.update(this.slider.options, 0);
    }
    else {
      this.slider.moveToIdx(targetIndex);
    }
  }


  observeViewPortChanges() {
    this.viewPortObserverSubscription = this.viewportRuler
      .change(300)
      .subscribe(() => {
        this.isDiscoverSpace ?  this.updateSliderForSpace() : this.updateSlider();
      });
  }


  // This method is responsible for setting the number of items to scroll
  updateSlider() {
    const { width } = this.viewportRuler.getViewportSize();
    if (width > 1100) {
      this.slidesToScroll = 4;
      this.changeConfigurationForWideViewPort();
    } else if (width <= 1100 && width >= 767) {
      this.slidesToScroll = 3;
      this.changeConfigurationForWideViewPort();
    } else if (width < 767) {
      this.slidesToScroll = 2;
      this.changeConfigurationForSmallerViewPort()
    } else {
      this.slidesToScroll = 2;
      this.changeConfigurationForWideViewPort();
    }
    return true;
  }


  updateSliderForSpace() {
    const { width } = this.viewportRuler.getViewportSize();
    if (width >= 1100) {
      this.slidesToScroll = 3;
      this.changeConfigurationForWideViewPort();
    } else if (width < 1100 && width >= 767) {
      this.slidesToScroll = 2;
      this.changeConfigurationForWideViewPort();
    } else if (width < 767) {
      this.slidesToScroll = 1;
      this.changeConfigurationForSmallerViewPort()
    } else {
      this.slidesToScroll = 1;
      this.changeConfigurationForWideViewPort();
    }
    return true;
  }

  changeConfigurationForSmallerViewPort() {
    const smallerViewPortConfig = { ...this.carouselConfig, ...{ loop: false, drag: false } };
    if (this.slider) {
      this.slider?.update(smallerViewPortConfig);
    } else {
      this.initializeSlider(smallerViewPortConfig);
    }
  }

  changeConfigurationForWideViewPort() {
    if (this.slider) {
      this.slider.update(this.carouselConfig);
    }
    if (this.slider) {
      this.slider?.update(this.carouselConfig);
    } else {
      this.initializeSlider(this.carouselConfig);
    }
  }


  onTouchStart(event: TouchEvent): void {
    this.touchStartX = event.changedTouches[0].screenX;
  }

  onTouchEnd(event: TouchEvent): void {
    this.touchEndX = event.changedTouches[0].screenX;
    this.handleSwipe();
  }
  private handleSwipe(): void {
    const swipeThreshold = 10;

    if (this.touchEndX < this.touchStartX - swipeThreshold) {
      this.nextPage();
    } else if (this.touchEndX > this.touchStartX + swipeThreshold) {
      this.previousPage();
    }
  }

  ngOnDestroy() {
    if (this.viewPortObserverSubscription) {
      this.viewPortObserverSubscription.unsubscribe();
    }
  }
}
