import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogModule} from "primeng/dialog";
import {ExternalLinkPopupComponent} from "./external-link-popup.component";


@NgModule({
  declarations: [
    ExternalLinkPopupComponent
  ],
  imports: [
    CommonModule,
    DialogModule
  ],
  exports: [
    ExternalLinkPopupComponent
  ]
})
export class ExternalLinkPopupModule { }
