import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {BehaviorSubject, take} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  isScrollingTop = new BehaviorSubject(false);
  isScrollingTopUpdated: boolean = false;


  propertyId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isMobileNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  propertyDetails: BehaviorSubject<{ id: number, companyId: number }> = new BehaviorSubject<{ id: number, companyId: number }>({ id: 0, companyId: 0 });

  constructor(private http: HttpClient) {
  }

  /**
   * [POST] api to send keep in touch email
   * @param data
   */
  sendKeepInTouchformation(data: any) {
    return this.http.post(`${environment.apiUrl}/contact`, data).pipe(
      take(1)
    );
  }

  /**
   * Get property id from route
   * @constructor
   */
  get PropertyId() {
    return this.propertyId.getValue();
  }

  get PropertyDetails() {
    return this.propertyDetails.getValue();
  }

}
