import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'imageSize'
})
export class ImageSizePipe implements PipeTransform {

  /**
   * Pipe transform function
   * @param value
   * @param size
   */
  transform(value: string, size: '_thumb' | '_hero_desk' | '_hero_phone' | '_card'): string {
    return this.appendStaticDataToImageName(value, size);
  }

  /**
   * To append size type to image
   * @param url Image URL
   * @param staticData Static data
   */
  appendStaticDataToImageName(url: string | undefined, staticData: string): string {
    if(url) {
      // Split the URL into parts (base URL and filename)
      const urlParts = url.split('/');
      const filename = urlParts[urlParts.length - 1];

      // Split the filename into name and extension
      const filenameParts = filename.split('.');
      const name = filenameParts.slice(0, -1).join('.');
      const extension = filenameParts[filenameParts.length - 1];

      // Append static data to the image name
      const newName = name + staticData;

      // Reconstruct the URL with the new name. If avif, return the original filename
      const newFilename = extension === 'avif' ? `${name}.${extension}` : `${newName}.${extension}`;
      urlParts[urlParts.length - 1] = newFilename;
      const newUrl = urlParts.join('/');

      return newUrl;
    } else {
      return  ''
    }
  }

}
