import { getGlobalScope } from '@amplitude/analytics-client-common';
import { getServerUrl } from './helpers';
/**
 * For very small payloads it's preferable to use the [Beacon API](https://developer.mozilla.org/en-US/docs/Web/API/Beacon_API).
 * While it doesn't provide 100% guarantees on sends, it greatly helps with overall reliability and page load performance. As
 * the Beacon API has a potential to fail due to size constraints we want to fall back to XHR if need be. This is mostly to
 * be used with 'pagehide' or 'beforeunload' events.
 *
 * Note there are only 3 CORS safelisted Content-Types you can send:
 *
 * - application/x-www-form-urlencoded
 * - multipart/form-data
 * - text/plain
 *
 * If we do not send one of these, some browsers like Chrome may not send this at all. Also we incur the overhead of a preflight
 * request. In our case we will add no additional content-type header. If you are trying to ping a server that requires this
 * header, you may want to use the regular fetch API or a different mechanism.
 */
var BeaconTransport = /** @class */function () {
  function BeaconTransport(context, config) {
    var globalScope = getGlobalScope();
    if (globalScope && globalScope.navigator && typeof globalScope.navigator.sendBeacon === 'function') {
      this.sendBeacon = function (pageUrl, payload) {
        try {
          if (globalScope.navigator.sendBeacon(pageUrl, JSON.stringify(payload))) {
            return true;
          }
        } catch (e) {
          // not logging error, since it would be hard to view and just adds overhead.
        }
        return false;
      };
    } else {
      this.sendBeacon = function () {
        return false;
      };
    }
    this.sendXhr = function (pageUrl, payload) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', pageUrl, true);
      xhr.setRequestHeader('Accept', '*/*');
      xhr.send(JSON.stringify(payload));
      return true;
    };
    this.basePageUrl = getServerUrl(config.serverZone);
    this.context = context;
  }
  BeaconTransport.prototype.send = function (deviceId, payload) {
    var _a = this.context,
      sessionId = _a.sessionId,
      type = _a.type;
    var urlParams = new URLSearchParams({
      device_id: deviceId,
      session_id: String(sessionId),
      type: String(type)
    });
    var pageUrl = "".concat(this.basePageUrl, "?").concat(urlParams.toString());
    // ideally send using the beacon API, but there is a chance it may fail, possibly due to a payload
    // size limit. in this case, try best effort to send using xhr.
    this.sendBeacon(pageUrl, payload) || this.sendXhr(pageUrl, payload);
  };
  return BeaconTransport;
}();
export { BeaconTransport };
