import {ResolveFn} from '@angular/router';
import {inject} from "@angular/core";
import {HomepageService} from "../services/homepage/homepage.service";

export const propertyDataResolver: ResolveFn<boolean> = async (route, state): Promise<any> => {
const homepageService = inject(HomepageService);
  if(!homepageService.PROPERTY_DETAILS) {
    await homepageService.fetchPageInformation(route.params['propertyId'])
  }
  return homepageService.PROPERTY_DETAILS!;
};
