import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApplyFnPipe} from "./apply-fn.pipe";


@NgModule({
  declarations: [ApplyFnPipe],
  imports: [
    CommonModule
  ],
  exports: [ApplyFnPipe]
})
export class ApplyFnModule { }
