import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MultiselectListboxComponent} from "./multiselect-listbox.component";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {ListboxModule} from "primeng/listbox";


@NgModule({
  declarations: [MultiselectListboxComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    InputTextModule,
    ButtonModule,
    ListboxModule
  ],
  exports: [
    MultiselectListboxComponent
  ]
})
export class MultiselectListboxModule { }
