import { genId, NodeType } from '../../../rrweb-snapshot/es/rrweb-snapshot.js';
import { EventType, IncrementalSource } from '../../../types/dist/rrweb-types.js';
import CrossOriginIframeMirror from './cross-origin-iframe-mirror.js';
class IframeManager {
  constructor(options) {
    this.iframes = new WeakMap();
    this.crossOriginIframeMap = new WeakMap();
    this.crossOriginIframeMirror = new CrossOriginIframeMirror(genId);
    this.crossOriginIframeRootIdMap = new WeakMap();
    this.mutationCb = options.mutationCb;
    this.wrappedEmit = options.wrappedEmit;
    this.stylesheetManager = options.stylesheetManager;
    this.recordCrossOriginIframes = options.recordCrossOriginIframes;
    this.crossOriginIframeStyleMirror = new CrossOriginIframeMirror(this.stylesheetManager.styleMirror.generateId.bind(this.stylesheetManager.styleMirror));
    this.mirror = options.mirror;
    if (this.recordCrossOriginIframes) {
      window.addEventListener('message', this.handleMessage.bind(this));
    }
  }
  addIframe(iframeEl) {
    this.iframes.set(iframeEl, true);
    if (iframeEl.contentWindow) this.crossOriginIframeMap.set(iframeEl.contentWindow, iframeEl);
  }
  addLoadListener(cb) {
    this.loadListener = cb;
  }
  attachIframe(iframeEl, childSn) {
    var _a;
    this.mutationCb({
      adds: [{
        parentId: this.mirror.getId(iframeEl),
        nextId: null,
        node: childSn
      }],
      removes: [],
      texts: [],
      attributes: [],
      isAttachIframe: true
    });
    (_a = this.loadListener) === null || _a === void 0 ? void 0 : _a.call(this, iframeEl);
    if (iframeEl.contentDocument && iframeEl.contentDocument.adoptedStyleSheets && iframeEl.contentDocument.adoptedStyleSheets.length > 0) this.stylesheetManager.adoptStyleSheets(iframeEl.contentDocument.adoptedStyleSheets, this.mirror.getId(iframeEl.contentDocument));
  }
  handleMessage(message) {
    const crossOriginMessageEvent = message;
    if (crossOriginMessageEvent.data.type !== 'rrweb' || crossOriginMessageEvent.origin !== crossOriginMessageEvent.data.origin) return;
    const iframeSourceWindow = message.source;
    if (!iframeSourceWindow) return;
    const iframeEl = this.crossOriginIframeMap.get(message.source);
    if (!iframeEl) return;
    const transformedEvent = this.transformCrossOriginEvent(iframeEl, crossOriginMessageEvent.data.event);
    if (transformedEvent) this.wrappedEmit(transformedEvent, crossOriginMessageEvent.data.isCheckout);
  }
  transformCrossOriginEvent(iframeEl, e) {
    var _a;
    switch (e.type) {
      case EventType.FullSnapshot:
        {
          this.crossOriginIframeMirror.reset(iframeEl);
          this.crossOriginIframeStyleMirror.reset(iframeEl);
          this.replaceIdOnNode(e.data.node, iframeEl);
          const rootId = e.data.node.id;
          this.crossOriginIframeRootIdMap.set(iframeEl, rootId);
          this.patchRootIdOnNode(e.data.node, rootId);
          return {
            timestamp: e.timestamp,
            type: EventType.IncrementalSnapshot,
            data: {
              source: IncrementalSource.Mutation,
              adds: [{
                parentId: this.mirror.getId(iframeEl),
                nextId: null,
                node: e.data.node
              }],
              removes: [],
              texts: [],
              attributes: [],
              isAttachIframe: true
            }
          };
        }
      case EventType.Meta:
      case EventType.Load:
      case EventType.DomContentLoaded:
        {
          return false;
        }
      case EventType.Plugin:
        {
          return e;
        }
      case EventType.Custom:
        {
          this.replaceIds(e.data.payload, iframeEl, ['id', 'parentId', 'previousId', 'nextId']);
          return e;
        }
      case EventType.IncrementalSnapshot:
        {
          switch (e.data.source) {
            case IncrementalSource.Mutation:
              {
                e.data.adds.forEach(n => {
                  this.replaceIds(n, iframeEl, ['parentId', 'nextId', 'previousId']);
                  this.replaceIdOnNode(n.node, iframeEl);
                  const rootId = this.crossOriginIframeRootIdMap.get(iframeEl);
                  rootId && this.patchRootIdOnNode(n.node, rootId);
                });
                e.data.removes.forEach(n => {
                  this.replaceIds(n, iframeEl, ['parentId', 'id']);
                });
                e.data.attributes.forEach(n => {
                  this.replaceIds(n, iframeEl, ['id']);
                });
                e.data.texts.forEach(n => {
                  this.replaceIds(n, iframeEl, ['id']);
                });
                return e;
              }
            case IncrementalSource.Drag:
            case IncrementalSource.TouchMove:
            case IncrementalSource.MouseMove:
              {
                e.data.positions.forEach(p => {
                  this.replaceIds(p, iframeEl, ['id']);
                });
                return e;
              }
            case IncrementalSource.ViewportResize:
              {
                return false;
              }
            case IncrementalSource.MediaInteraction:
            case IncrementalSource.MouseInteraction:
            case IncrementalSource.Scroll:
            case IncrementalSource.CanvasMutation:
            case IncrementalSource.Input:
              {
                this.replaceIds(e.data, iframeEl, ['id']);
                return e;
              }
            case IncrementalSource.StyleSheetRule:
            case IncrementalSource.StyleDeclaration:
              {
                this.replaceIds(e.data, iframeEl, ['id']);
                this.replaceStyleIds(e.data, iframeEl, ['styleId']);
                return e;
              }
            case IncrementalSource.Font:
              {
                return e;
              }
            case IncrementalSource.Selection:
              {
                e.data.ranges.forEach(range => {
                  this.replaceIds(range, iframeEl, ['start', 'end']);
                });
                return e;
              }
            case IncrementalSource.AdoptedStyleSheet:
              {
                this.replaceIds(e.data, iframeEl, ['id']);
                this.replaceStyleIds(e.data, iframeEl, ['styleIds']);
                (_a = e.data.styles) === null || _a === void 0 ? void 0 : _a.forEach(style => {
                  this.replaceStyleIds(style, iframeEl, ['styleId']);
                });
                return e;
              }
          }
        }
    }
  }
  replace(iframeMirror, obj, iframeEl, keys) {
    for (const key of keys) {
      if (!Array.isArray(obj[key]) && typeof obj[key] !== 'number') continue;
      if (Array.isArray(obj[key])) {
        obj[key] = iframeMirror.getIds(iframeEl, obj[key]);
      } else {
        obj[key] = iframeMirror.getId(iframeEl, obj[key]);
      }
    }
    return obj;
  }
  replaceIds(obj, iframeEl, keys) {
    return this.replace(this.crossOriginIframeMirror, obj, iframeEl, keys);
  }
  replaceStyleIds(obj, iframeEl, keys) {
    return this.replace(this.crossOriginIframeStyleMirror, obj, iframeEl, keys);
  }
  replaceIdOnNode(node, iframeEl) {
    this.replaceIds(node, iframeEl, ['id', 'rootId']);
    if ('childNodes' in node) {
      node.childNodes.forEach(child => {
        this.replaceIdOnNode(child, iframeEl);
      });
    }
  }
  patchRootIdOnNode(node, rootId) {
    if (node.type !== NodeType.Document && !node.rootId) node.rootId = rootId;
    if ('childNodes' in node) {
      node.childNodes.forEach(child => {
        this.patchRootIdOnNode(child, rootId);
      });
    }
  }
}
export { IframeManager };