import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlaceholderImgDirectiveDirective} from "./placeholder-img-directive.directive";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PlaceholderImgDirectiveDirective
  ],
  exports: [PlaceholderImgDirectiveDirective]
})
export class PlaceholderImageModule { }
