import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';
import {DefaultValueAccessor} from "@angular/forms";
import {PLACEHOLDER_URL, SPACE_PLACEHOLDER} from "../../constants";

@Directive({
  selector: '[appPlaceholderImgDirective]',
  standalone: true
})
export class PlaceholderImgDirectiveDirective extends DefaultValueAccessor {

  // To provide a custom placeholder if required
  @Input() type: string = 'product';

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }

  @HostListener('error', ['$event']) onError($event: Event) {
    const target = $event.target as HTMLImageElement;
    if(this.type === 'space') {
      target.src = SPACE_PLACEHOLDER;
    } else {
      target.src = PLACEHOLDER_URL;
    }

    target.srcset = '';
    target.sizes = '';
  }

}
