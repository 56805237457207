<!-- Modal -->
<ng-template #productInformationModal let-modal>
  <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
      <span>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.919 1.08132C14.544 0.706292 13.9365 0.706292 13.5615 1.08132L7.9999 6.64287L2.43835 1.08132C2.06332 0.706932 1.45643 0.707556 1.0814 1.08194C0.707015 1.45697 0.706375 2.06386 1.08078 2.43889L6.64233 8.00044L1.08078 13.562C0.70639 13.937 0.707014 14.5439 1.0814 14.9189C1.45643 15.2933 2.06332 15.294 2.43835 14.9196L7.9999 9.35801L13.5615 14.9196C13.9365 15.294 14.5434 15.2933 14.9184 14.9189C15.2928 14.5439 15.2934 13.937 14.919 13.562L9.35748 8.00044L14.919 2.43889C15.099 2.25888 15.2003 2.0145 15.2003 1.7601C15.2003 1.50571 15.099 1.26133 14.919 1.08132L14.919 1.08132Z" fill="#C7C7C7"/>
        </svg>

      </span>
    </button>
    <div class="product_image" [ngClass]="{ 'first-product': productList?.length === 1, 'last-product': productList?.length === 1}">
      <div class="img_contain">
      <img class="product_figure" src="{{product.image | imageSize: '_hero_desk'}}"
           appPlaceholderImgDirective [alt]="product.productName" />
      @if (product.isFavourite) {
        <span class="tag_ui fav_tag">
          <i class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <path d="M5.9365 1.70654C4.81904 1.70654 4.19046 2.63776 4.19046 3.10337C4.19046 2.63776 3.56189 1.70654 2.44443 1.70654C1.32697 1.70654 1.04761 2.63776 1.04761 3.10337C1.04761 5.54781 4.19046 7.29384 4.19046 7.29384C4.19046 7.29384 7.33332 5.54781 7.33332 3.10337C7.33332 2.63776 7.05396 1.70654 5.9365 1.70654Z" fill="white" stroke="white" stroke-width="0.609524" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </i>
          Our Favorites
        </span>
      }

      @if (product.isLocal) {
        <span class="tag_ui local_tag">
          <i class="icon">
            <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.69168 1.5464C5.48702 1.12471 5.1842 0.76356 4.81106 0.496141C4.43793 0.228723 4.00645 0.0636207 3.55629 0.0160138C3.35266 -0.00533793 3.14749 -0.00533793 2.94385 0.0160138C2.4937 0.0636519 2.06223 0.228749 1.68908 0.496136C1.31593 0.763523 1.01307 1.12462 0.808324 1.54626C0.550723 2.08424 0.451222 2.68985 0.522355 3.28682C0.593487 3.88378 0.83207 4.4454 1.20805 4.90093L3.0415 7.23039C3.06682 7.26257 3.09865 7.2885 3.1347 7.3063C3.17075 7.3241 3.21013 7.33333 3.25 7.33333C3.28988 7.33333 3.32925 7.3241 3.3653 7.3063C3.40136 7.2885 3.43319 7.26257 3.45851 7.23039L5.29195 4.90093C5.66793 4.44542 5.90651 3.88383 5.97765 3.28689C6.04878 2.68995 5.94928 2.08436 5.69168 1.5464ZM3.25 3.94857C3.03647 3.94857 2.82774 3.8824 2.6502 3.75843C2.47265 3.63445 2.33427 3.45825 2.25256 3.25209C2.17085 3.04594 2.14947 2.81909 2.19112 2.60023C2.23278 2.38138 2.33561 2.18035 2.48659 2.02256C2.63758 1.86477 2.82995 1.75732 3.03938 1.71379C3.24881 1.67026 3.46588 1.6926 3.66316 1.77799C3.86043 1.86338 4.02905 2.00799 4.14768 2.19353C4.26631 2.37906 4.32963 2.5972 4.32963 2.82034C4.32929 3.11946 4.21544 3.40622 4.01304 3.61773C3.81065 3.82924 3.53623 3.94822 3.25 3.94857Z" fill="white"/>
            </svg>
          </i>
          Local
        </span>
      }
      <div class="next_prev_btn">
        <button class="prev_btn" (dblclick)="$event.preventDefault()" (click)="decrementSlider()"><img src="assets/images/prev_icon.svg" alt="prev"/> </button>
        <button class="next_btn" (dblclick)="$event.preventDefault()" (click)="incrementSlider()"><img src="assets/images/next_icon.svg" alt="next"/></button>
      </div>
      </div>
    </div>
    <div class="product_detail">
      <div class="top_sec">
        <span (click)="shareProduct()" class="share_icon"><img src="assets/images/share_icon.svg" alt="share" /> </span>
        @if(product.brandName) {
          <label class="brand_name">{{product.brandName}}</label>
        }
        <p class="product_name">{{ product.productName }}</p>
      </div>
      <div class="bottom_sec">
        <!-- ellipsis=", and 2 other" -->
        @if(product.subspaceList.length) {
<!--          <div class="space_list" id="foundIn" #foundIn>-->
<!--            Found in-->
<!--            @for(space of viewSpaces; track space) {-->
<!--              <a (click)="modal.close(); amplitudeService.productDrawerSpaceClick(product, space.title)" routerLink="/{{footerService.PropertyId}}/{{space?.subspaceId}}">{{space.title}}</a>{{$last ? ' ' : ', '}}-->
<!--              @if (viewSpaces.length != this.product.subspaceList.length && $last) {-->
<!--                <span>and {{this.product.subspaceList.length - viewSpaces.length}} {{ this.product.subspaceList.length - viewSpaces.length !== 1 ? ' others' : ' other'}}</span>-->
<!--              }-->
<!--            }-->
<!--          </div>-->
          <div class="found_in_filter_list"  id="foundIn" #foundIn>
          <label class="label_text">Found in</label>
            <ul>
              @for(space of product.subspaceList; track space) {
                <li (click)="onSubspaceClick($event, modal, space)">
                  {{space.title}}
                </li>
              }

            </ul>
          </div>
        }
        <a appAffiliateUrl [product]="product"  href="{{product.affiliateUrl ? (product.affiliateUrl | applyFn: getWebLink) : (product.productUrl | applyFn: getWebLink)}}" target="_blank" (click)="productShopNowClick($event, product)" class="button_primary sm">Shop Now</a>
      </div>
    </div>
  </div>
</ng-template>

<app-external-link-popup [(externalLink)]="externalLinkPopup"></app-external-link-popup>
