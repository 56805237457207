<section class="all_product_page no_hero_banner">
  <section class="inner_page_banner no_image">
    <div class="mx-1660 container">
      <div class="inner_banner_sec">
        <div class="left_sec">
          <div class="hero_desc">
            <a class="back-btn link-primary-btn p-0" (click)="amplitudeService.navClickEvent('global_nav', 'back')" routerLink="/{{footerService.PropertyId}}">
              <span class="el-icon">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.1875 20.5H14.25M14.25 20.5L19.5 15.25M14.25 20.5L19.5 25.75" stroke="#666666" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
                  <g opacity="0.3">
                  <circle cx="20" cy="20" r="19.75" transform="matrix(-1 0 0 1 40 0)" stroke="black" stroke-width="0.5"/>
                  </g>
                  </svg>
              </span>
<!--              <p class="el-text">HOME</p>-->
            </a>
            <h1 class="heading_1">All Products</h1>
          </div>
        </div>
        <div class="right_sec">
        </div>
      </div>
    </div>
  </section>
  <!-- Filet and Product Listing -->
  <app-product-view [spaceDetails]="homepageService.PROPERTY_DETAILS?.showroomDetails?.allProductList!"></app-product-view>
  <!-- End Filet and Product Listing -->

  <app-discover-spaces pageType="all_products"></app-discover-spaces>

  <!--Co Branding Section Start-->
  <app-co-branding></app-co-branding>
  <!--Co Branding Section End-->
</section>
