import {Component, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {FooterService} from "../../services/footer/footer.service";
import {IsActiveMatchOptions} from "@angular/router";
import {HomepageService} from "../../services/homepage/homepage.service";
import {AmplitudeService} from "../../services/tracking/amplitude.service";
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import {SpaceDetails} from "../../interfaces/spaceDetails";
import {chunk} from "lodash-es";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {

  _pageType: 'isInnerPage' = 'isInnerPage';
  @Input() set pageType(type: 'isInnerPage') {
    this._pageType = type;
  }

  get pageType(): 'isInnerPage' {
    return this._pageType;
  }

  showMobileMenu: boolean = false;
  showDropdownMenu:any;
  isScrolled = false;

  routerLinkActiveOptions: IsActiveMatchOptions = {matrixParams: 'ignored', queryParams: 'ignored', paths: 'exact', fragment: 'ignored'}

  @Input() isInnerPage: boolean = false;

  // To determine whether it is space switcher or not
  openedViaSpaceHeader: boolean = false

  isScrollingTop: boolean = false;

  @ViewChild('spacesDropdown') spacesDropdown: NgbDropdown | undefined

  isSpaceImages: boolean | undefined = undefined;
  displaySpaces: SpaceDetails[][] = [];
  spaceList: SpaceDetails[] = []

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public footerService: FooterService,
    public homepageService: HomepageService,
    public amplitudeService: AmplitudeService,
  ) {
    this.footerService.isMobileNavOpen.subscribe(value => {
      this.openedViaSpaceHeader = !value
      this.closeBtnClick(value);
    })
  }


  ngOnInit(): void {
    this.footerService.isScrollingTop.subscribe({
      next: res => {
        this.isScrollingTop = res;
      },
      error: () => {
      }
    });
    this.spaceList = this.homepageService.PROPERTY_DETAILS?.spaceList.filter(each => !!each.productCount)!;
    this.isSpaceImages = this.spaceList.some((each: SpaceDetails) => each.image != '');
    if(!this.isSpaceImages) {
      this.displaySpaces = this.spaceList.length < 5 ?
        chunk(this.spaceList, this.spaceList.length) :
        chunk(this.spaceList, Math.ceil(this.spaceList.length / 2));
    }
  }

  subMenuBtnClick(closeMenu: boolean = false, navName: string = '') {
    this.showDropdownMenu = closeMenu ? false : !this.showDropdownMenu;
  }

  /**
   * To open/close hamburger menu
   * @param closeMenu
   */
  closeBtnClick(closeMenu: boolean = false) {
    this.showMobileMenu = closeMenu ? false : !this.showMobileMenu;

    // Reset openedViaSpaceHeader on close
    if(!this.showMobileMenu) {
      this.openedViaSpaceHeader = false;
    }
    if(isPlatformBrowser(this.platformId)) {
      document.body.classList.toggle('overflow', this.showMobileMenu);
      if(document.getElementById('navbar_content_section')?.scrollTop) {
        document.getElementById('navbar_content_section')?.scroll({left: 0, top: 0, behavior: 'instant'})
      }
    }
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    if(isPlatformBrowser(this.platformId)) {
      const scrollPosition = window.scrollY || window.pageYOffset;
      this.isScrolled = scrollPosition >= 40;
    }
  }

  /**
   * Track nav click
   * @param optionSelected Which option was selected
   * @param location Whether mobile menu was option was selected or header nav
   */
  navClickTracker(optionSelected: string, location: 'global_nav' | 'mobile_nav') {
    const clickLocation = location === 'global_nav' ? 'global_nav' : this.openedViaSpaceHeader ? 'space_switcher' : 'hamburger_menu';
    this.amplitudeService.navClickEvent(clickLocation, optionSelected);
    this.openedViaSpaceHeader = false;
  }

  /**
   * Getter to disable spaces button
   */
  get listOfSpacesWithProducts(): boolean {
	  return this.spaceList.length <= 0;
  }

}
