import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TitleDisplayComponent} from "./title-display.component";


@NgModule({
  declarations: [TitleDisplayComponent],
  imports: [
    CommonModule,
  ],
  exports :[
    TitleDisplayComponent
  ]
})
export class TitleDisplayModule { }
