import { isNativeShadowDom } from '../../../rrweb-snapshot/es/rrweb-snapshot.js';
import { patch, inDom } from '../utils.js';
import { initMutationObserver, initScrollObserver, initAdoptedStyleSheetObserver } from './observer.js';
class ShadowDomManager {
  constructor(options) {
    this.shadowDoms = new WeakSet();
    this.restoreHandlers = [];
    this.mutationCb = options.mutationCb;
    this.scrollCb = options.scrollCb;
    this.bypassOptions = options.bypassOptions;
    this.mirror = options.mirror;
    this.init();
  }
  init() {
    this.reset();
    this.patchAttachShadow(Element, document);
  }
  addShadowRoot(shadowRoot, doc) {
    if (!isNativeShadowDom(shadowRoot)) return;
    if (this.shadowDoms.has(shadowRoot)) return;
    this.shadowDoms.add(shadowRoot);
    const observer = initMutationObserver(Object.assign(Object.assign({}, this.bypassOptions), {
      doc,
      mutationCb: this.mutationCb,
      mirror: this.mirror,
      shadowDomManager: this
    }), shadowRoot);
    this.restoreHandlers.push(() => observer.disconnect());
    this.restoreHandlers.push(initScrollObserver(Object.assign(Object.assign({}, this.bypassOptions), {
      scrollCb: this.scrollCb,
      doc: shadowRoot,
      mirror: this.mirror
    })));
    setTimeout(() => {
      if (shadowRoot.adoptedStyleSheets && shadowRoot.adoptedStyleSheets.length > 0) this.bypassOptions.stylesheetManager.adoptStyleSheets(shadowRoot.adoptedStyleSheets, this.mirror.getId(shadowRoot.host));
      this.restoreHandlers.push(initAdoptedStyleSheetObserver({
        mirror: this.mirror,
        stylesheetManager: this.bypassOptions.stylesheetManager
      }, shadowRoot));
    }, 0);
  }
  observeAttachShadow(iframeElement) {
    if (!iframeElement.contentWindow || !iframeElement.contentDocument) return;
    this.patchAttachShadow(iframeElement.contentWindow.Element, iframeElement.contentDocument);
  }
  patchAttachShadow(element, doc) {
    const manager = this;
    this.restoreHandlers.push(patch(element.prototype, 'attachShadow', function (original) {
      return function (option) {
        const shadowRoot = original.call(this, option);
        if (this.shadowRoot && inDom(this)) manager.addShadowRoot(this.shadowRoot, doc);
        return shadowRoot;
      };
    }));
  }
  reset() {
    this.restoreHandlers.forEach(handler => {
      try {
        handler();
      } catch (e) {}
    });
    this.restoreHandlers = [];
    this.shadowDoms = new WeakSet();
  }
}
export { ShadowDomManager };