import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {
  NgbDropdown,
  NgbDropdownAnchor,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle
} from "@ng-bootstrap/ng-bootstrap";
import {SkeletonModule} from "primeng/skeleton";
import {ImageSizeModule} from "../../shared/pipes/imageSize/image-size.module";
import {HeaderComponent} from "./header.component";
import {PlaceholderImageModule} from "../../shared/directives/placeholderImageDirective/placeholder-image.module";


@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
    NgbDropdownAnchor,
    SkeletonModule,
    ImageSizeModule,
    PlaceholderImageModule,
  ],
  exports:[
    HeaderComponent
  ]
})
export class HeaderModule { }
