<div [ngClass]="{ 'p-listbox p-component': true, 'p-disabled': disabled }" [ngStyle]="style" [class]="styleClass">
  <div class="p-listbox-header">
    @if(checkbox && multiple && showToggleAll && filterValue == '') {
      <div class="p-checkbox p-component" [ngClass]="{ 'p-checkbox-disabled': disabled || toggleAllDisabled }">
        @if(filterValue == '') {
          <div class="p-hidden-accessible">
            <input type="checkbox" readonly="readonly" [checked]="allChecked" (focus)="onHeaderCheckboxFocus()" (blur)="onHeaderCheckboxBlur()" (keydown.space)="toggleAll($event)" [disabled]="disabled || toggleAllDisabled" />
          </div>
        }
        @if(filterValue == '') {
          <div #headerchkbox class="p-checkbox-box" [ngClass]="{ 'p-highlight': allChecked, 'p-focus': headerCheckboxFocus, 'p-disabled': disabled || toggleAllDisabled }" (click)="toggleAll($event)">
            <span class="p-checkbox-icon" [ngClass]="{ 'pi pi-check': allChecked }"></span>
          </div>
        }
        @if(filterValue == '') {
          <div class="header-content" [ngClass]="{ 'p-highlight': allChecked, 'p-focus': headerCheckboxFocus, 'p-disabled': disabled || toggleAllDisabled }" (click)="toggleAll($event)">
            Select All
          </div>
        }
      </div>
    }
    @if(filterValue != '') {
      <div class="spacer22" style="height: 22px"></div>
    }

    @if(filterTemplate) {
      <ng-container>
        <ng-container *ngTemplateOutlet="filterTemplate; context: { options: filterOptions }"></ng-container>
      </ng-container>
    } @else {
      @if(filter) {
        <div class="p-listbox-filter-container">
          <input
            #filter
            type="text"
            [value]="filterValue || ''"
            (input)="onFilter($event)"
            class="p-listbox-filter p-inputtext p-component"
            [disabled]="disabled"
            [attr.placeholder]="filterPlaceHolder"
            [attr.aria-label]="ariaFilterLabel"
          />
          <span class="p-listbox-filter-icon pi" (click)="clearFilter()" [ngClass]="{'pi-times': filterValue != '', 'pi-search': filterValue == '' }"></span>
        </div>
      }
    }
  </div>
  <div [ngClass]="'p-listbox-list-wrapper'" [ngStyle]="listStyle" [class]="listStyleClass">
    <ul class="p-listbox-list" role="listbox" [attr.aria-multiselectable]="multiple">
      @if(!group) {
        <ng-container>
          <ng-container *ngTemplateOutlet="itemslist; context: { $implicit: optionsToRender }"></ng-container>
        </ng-container>
      }
      <ng-template #itemslist let-optionsToDisplay>
        @if(virtualScroll && !isEmpty()) {
          <cdk-virtual-scroll-viewport #itemListScroller [ngStyle]="listStyle" [orientation]="'vertical'" [itemSize]="itemSize">
            <li
              *cdkVirtualFor="let option of optionsToDisplay; let i = index"
              [attr.tabindex]="disabled || isOptionDisabled(option) ? null : '0'"
              pRipple
              [ngClass]="{ 'p-listbox-item': true, 'p-highlight': isSelected(option), 'p-disabled': this.isOptionDisabled(option) }"
              role="option"
              [attr.aria-label]="getOptionLabel(option)"
              [attr.aria-selected]="isSelected(option)"
              (click)="onOptionClick($event, option)"
              (dblclick)="onOptionDoubleClick($event, option)"
              (touchend)="onOptionTouchEnd(option)"
              (keydown)="onOptionKeyDown($event, option)"
            >
              @if(checkbox) {
                <div class="p-checkbox p-component" [ngClass]="{ 'p-checkbox-disabled': disabled || isOptionDisabled(option) }">
                  <div class="p-checkbox-box" [ngClass]="{ 'p-highlight': isSelected(option) }">
                    <span class="p-checkbox-icon" [ngClass]="{ 'pi pi-check': isSelected(option) }"></span>
                  </div>
                </div>
              }
              @if(!itemTemplate) {
                <span>{{ getOptionLabel(option) }}</span>
              }
              <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option, index: i }"></ng-container>
            </li>
          </cdk-virtual-scroll-viewport>
        } @else {
          @for(option of optionsToDisplay; track option; let i = $index) {
            <li [attr.tabindex]="disabled || isOptionDisabled(option) ? null : '0'"
                pRipple
                [ngClass]="{ 'p-listbox-item': true, 'p-highlight': isSelected(option), 'p-disabled': this.isOptionDisabled(option) }"
                role="option"
                [attr.aria-label]="getOptionLabel(option)"
                [attr.aria-selected]="isSelected(option)"
                (click)="onOptionClick($event, option)"
                (dblclick)="onOptionDoubleClick($event, option)"
                (touchend)="onOptionTouchEnd(option)"
                (keydown)="onOptionKeyDown($event, option)"
            >
              @if(checkbox) {
                <div class="p-checkbox p-component" [ngClass]="{ 'p-checkbox-disabled': disabled || isOptionDisabled(option) }">
                  <div class="p-checkbox-box" [ngClass]="{ 'p-highlight': isSelected(option) }">
                    <span class="p-checkbox-icon" [ngClass]="{ 'pi pi-check': isSelected(option) }"></span>
                  </div>
                </div>
              }
              @if(!itemTemplate) {
                <span>{{ getOptionLabel(option) }}</span>
              }
              <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option, index: i }"></ng-container>
            </li>
          }
        }
      </ng-template>
      @if(hasFilter() && isEmpty()) {
        <li class="p-listbox-empty-message">
          @if(!emptyFilterTemplate && !emptyTemplate) {
            <ng-container>
              {{ emptyFilterMessageLabel }}
            </ng-container>
          }
          <ng-container #emptyFilter *ngTemplateOutlet="emptyFilterTemplate || emptyTemplate"></ng-container>
        </li>
      }
      @if(!hasFilter() && isEmpty()) {
        <li class="p-listbox-empty-message">
          @if(!emptyTemplate) {
            <ng-container>
              {{ emptyMessageLabel }}
            </ng-container>
          }
          <ng-container #empty *ngTemplateOutlet="emptyTemplate"></ng-container>
        </li>
      }
    </ul>
  </div>
  @if(footerFacet || footerTemplate) {
    <div class="p-listbox-footer">
      <ng-content select="p-footer"></ng-content>
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
  }
</div>
