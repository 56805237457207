import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PluralSingularPipe} from "./plural-singular.pipe";


@NgModule({
  declarations: [PluralSingularPipe],
  imports: [
    CommonModule
  ],
  exports: [
    PluralSingularPipe
  ]
})
export class PluralSingularModule { }
