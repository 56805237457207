import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DiscoverSpacesComponent} from "./discover-spaces.component";
import {RouterLink} from "@angular/router";
import {AnimateOnScrollModule} from "primeng/animateonscroll";
import {ApplyFnModule} from "../../pipes/applyFn/apply-fn.module";
import {SkeletonModule} from "primeng/skeleton";
import {WheelThrottleModule} from "../../directives/wheelThrottleDirective/wheel-throttle.module";
import {ImageSizeModule} from "../../pipes/imageSize/image-size.module";
import {PlaceholderImageModule} from "../../directives/placeholderImageDirective/placeholder-image.module";
import {CarouselComponent} from '../carousel/carousel.component';
import {SpaceFilterPipe} from "../../pipes/space-filter/space-filter.pipe";


@NgModule({
  declarations: [DiscoverSpacesComponent],
  imports: [
    CommonModule,
    RouterLink,
    AnimateOnScrollModule,
    ApplyFnModule,
    SkeletonModule,
    PlaceholderImageModule,
    WheelThrottleModule,
    ImageSizeModule,
    CarouselComponent,
    SpaceFilterPipe
  ],
  exports : [
    DiscoverSpacesComponent
  ]
})
export class DiscoverSpacesModule { }
