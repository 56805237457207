import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HomepageService} from "../../services/homepage/homepage.service";
import {FooterService} from "../../services/footer/footer.service";
import {AmplitudeService} from "../../services/tracking/amplitude.service";
import {ActivatedRoute} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";
import {SkimlinksService} from "../../services/tracking/skimlinks.service";

@Component({
  selector: 'app-our-favourites',
  templateUrl: './our-favourites.component.html',
  styleUrl: './our-favourites.component.scss'
})
export class OurFavouritesComponent implements OnInit {
  constructor(
    public homepageService: HomepageService,
    public footerService: FooterService,
    public amplitudeService: AmplitudeService,
    public activatedRoute: ActivatedRoute,
    public skimlinksService: SkimlinksService,
    @Inject(PLATFORM_ID) public platformId: Object,
  ) {
  }

  ngOnInit() {
    this.amplitudeService.screenImpressionEvent('our_favorites')
    this.homepageService.updateMetaAndTitleTags(`Shop Your Stay - ${this.homepageService.PROPERTY_DETAILS?.showroomDetails?.ourFavoriteProductList.title!} | ${this.homepageService.PROPERTY_DETAILS?.showroomDetails.showroomName!}`, this.homepageService.PROPERTY_DETAILS?.showroomDetails?.image!)
    if(isPlatformBrowser(this.platformId)) {
      this.skimlinksService.loadAffliateLinks(`${this.footerService.PropertyId}-${this.footerService.PropertyDetails.id}`);
    }
  }

}
