import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'pluralSingular'
})
export class PluralSingularPipe implements PipeTransform {

  transform(number: number, singularText: string, pluralText: string): string {
    if (number === 1) {
      return singularText;
    } else {
      return pluralText;
    }
  }

}
