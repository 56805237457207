import {Routes} from '@angular/router';
import {Error404Component} from "./error-404/error-404.component";
import {propertyDataResolver} from "./resolvers/property-data.resolver";
import {MinoanWebsiteRedirectComponent} from "./base/minoan-website-redirect/minoan-website-redirect.component";
import {HomepageComponent} from "./showrooms/homepage/homepage.component";
import {mobileDeviceGuard} from "./guards/mobile-device.guard";
import {SpacesComponent} from "./showrooms/spaces/spaces.component";
import {spaceGuard} from "./guards/space.guard";
import {OurFavouritesComponent} from "./showrooms/our-favourites/our-favourites.component";
import {localItemsGuard} from "./guards/local-items.guard";
import {LocalItemsComponent} from "./showrooms/local-items/local-items.component";
import {AllProductsComponent} from "./showrooms/all-products/all-products.component";

export const routes: Routes = [
  {
    path: 'error',
    component: Error404Component,
  },
  {
    path: '',
    component: MinoanWebsiteRedirectComponent,
  },
  // {
  //   path: ':propertyId',
  //   loadChildren: () => import('./showrooms/showrooms.module').then(m => m.ShowroomsModule),
  //   resolve: {propertyData: propertyDataResolver}
  // },
  {
    path: ':propertyId',
    resolve: {propertyData: propertyDataResolver},
    children: [
      {
        path: '',
        component: HomepageComponent,
        canActivate: [mobileDeviceGuard],
      },
      {
        path: 'our-favorites',
        component: OurFavouritesComponent,
        canActivate: [localItemsGuard, mobileDeviceGuard],
      },
      {
        path: 'local-items',
        component: LocalItemsComponent,
        canActivate: [localItemsGuard, mobileDeviceGuard],
      },
      {
        path: 'all-products',
        component: AllProductsComponent,
        canActivate: [mobileDeviceGuard]
      },
      {
        path: ':spaceId',
        component: SpacesComponent,
        canActivate: [spaceGuard, mobileDeviceGuard],
      },
      {
        path: '**',
        redirectTo: ''
      }]
  }


];
