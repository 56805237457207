import {Pipe, PipeTransform} from '@angular/core';
import {SpaceDetails} from "../../../interfaces/spaceDetails";

@Pipe({
  name: 'spaceFilter',
  standalone: true
})
export class SpaceFilterPipe implements PipeTransform {

  transform(spaces: SpaceDetails[] | any, currentSpaceId: string): any {
    const filteredSpaces = spaces.filter((space:SpaceDetails) => {
      if(currentSpaceId) {
        return space.subspaceName !== currentSpaceId && !!space.productCount;
      } else {
        return !!space.productCount
      }
    });
    // Double and return if length between 3 and 6 to prevent 2nd page issue
    return filteredSpaces.length >= 3 && filteredSpaces.length <= 6 ? [...filteredSpaces, ...filteredSpaces] : [...filteredSpaces]
  }

}
