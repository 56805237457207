import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Product, SubspaceList} from "../../../interfaces/product";
import {NgbActiveModal, NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {isPlatformBrowser} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {FooterService} from "../../../services/footer/footer.service";
import {AmplitudeService} from "../../../services/tracking/amplitude.service";
import {getWebLink} from "../../../utils/common-function";
import {HomepageService} from "../../../services/homepage/homepage.service";

@Component({
  selector: 'app-product-detail-view',
  templateUrl: './product-detail-view.component.html',
  styleUrl: './product-detail-view.component.scss'
})
export class ProductDetailViewComponent implements OnChanges{

  @Input() productList: Product[] | undefined;
  @Input() productIndex: number = -1;
  @Output() productIndexChange = new EventEmitter<number>();
  @Input() showProductModal: boolean = false;
  @Output() showProductModalChange = new EventEmitter<boolean>();

  @Input({required: true, transform: (value: string) => {
      let link = '';
      if(value.includes('all_products')) {
        link = 'all-products';
      } else if (value.includes('our_favorite')) {
        link = 'our-favorites';
      } else if (value.includes('local_items')) {
        link = 'local-items';
      } else if (link === '') {
        link = '';
      } else {
        link = value;
      }
      return link;
    }}) previousURL: string = '';

  @ViewChild('productInformationModal') productInformationModal: TemplateRef<any> | undefined
  @ViewChild('foundIn') foundIn: ElementRef | undefined;

  modalOpenRef: NgbModalRef | undefined

  viewSpaces: SubspaceList[] = [];

  isSubspaceClick: boolean = false;

  externalLinkPopup: boolean = false;

  constructor(
    public modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    public amplitudeService: AmplitudeService,
    public footerService: FooterService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public homepageService: HomepageService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['showProductModal']) {
      if(changes['showProductModal'].currentValue) {
        if(isPlatformBrowser(this.platformId)) {
          this.modalOpenRef = this.modalService.open(this.productInformationModal, {modalDialogClass: 'product_modal_wrapper'})
        }
        this.isSubspaceClick = false;

        this.modalOpenRef?.result.finally(() => {
          this.showProductModalChange.emit(false);
          if(!this.isSubspaceClick) {
            this.isSubspaceClick = false;
            this.homepageService.selectedSpace = undefined;

            const {route, queryParams, spaceDetails} = this.getCurrentSubspaceRoute();
            this.router.navigate([route], {queryParams}).then(() => {
              this.homepageService.shouldScroll = true;

              // For our favorites, all products, local items
              if(spaceDetails) {
                this.homepageService.updateMetaAndTitleTags(`Shop Your Stay - ${spaceDetails?.title!} | ${this.showroomDetails?.showroomName!}`, this.showroomDetails?.image!)
              } else {
                this.homepageService.updateMetaAndTitleTags(`Shop Your Stay | ${this.showroomDetails?.showroomName!}`, this.showroomDetails?.image!)
              }

            });
          }
        })
        // Amplitude event
        this.amplitudeService.productDrawerImpression(this.product)
      } else {
        this.modalOpenRef?.close()
        if(this.modalService.hasOpenModals()) {
          this.modalService.dismissAll()
        }
      }
    }
  }

  updateSpaces() {
    this.viewSpaces = [];
    this.viewSpaces = [this.product.subspaceList[0]];
    this.setSpaces();
  }

  setSpaces() {
    // setTimeout(() => {
    //   if(isPlatformBrowser(this.platformId)) {
    //     const divElement = document.getElementById('foundIn')
    //     if(divElement) {
    //       const computedStyle = window.getComputedStyle(divElement);
    //       const lineHeight = parseFloat(computedStyle.lineHeight);
    //       const containerHeight = divElement.clientHeight;
    //       const lineCount = containerHeight / lineHeight;
    //       if(lineCount <= 2) {
    //         const l = this.viewSpaces.length;
    //         if(this.product.subspaceList.length > this.viewSpaces.length) {
    //           this.viewSpaces.push(this.product.subspaceList[l]);
    //           this.setSpaces();
    //         }
    //       } else {
    //         if(this.viewSpaces.length > 1) {
    //           this.viewSpaces.pop();
    //         }
    //       }
    //     }
    //   }
    // })

  }

  /**
   * To move to previous product
   */
  decrementSlider() {
    const index = this.productIndex === 0 ? this.productList?.length! - 1 : this.productIndex - 1;

    const product = this.productList?.[index]!;
    let {route, queryParams} = this.getCurrentSubspaceRoute();
    queryParams = {
      ...queryParams,
      productId: product.showroomProductName,
    }
    this.router.navigate([route], {queryParams}).then(() => {
      this.homepageService.shouldScroll = true;
    })
    this.amplitudeService.productDrawerArrowClick();
  }

  /**
   * To move to next product
   */
  incrementSlider() {
    const index = this.productIndex === this.productList?.length! - 1 ? 0 : this.productIndex + 1;
    const product = this.productList?.[index]!;
    let {route, queryParams} = this.getCurrentSubspaceRoute();
    queryParams = {
      ...queryParams,
      productId: product.showroomProductName,
    }
    this.router.navigate([route], {queryParams}).then()
    this.amplitudeService.productDrawerArrowClick();
  }

  /**
   * To open browser share menu
   */
  shareProduct() {
    this.amplitudeService.productDrawerShareClick(this.product)
    if(isPlatformBrowser(this.platformId) && navigator.share) {
      const {pathname, protocol, host} = window.location;
      // console.log(window.location.href)
      const shareUrl = `${protocol}//${host}/shop${this.router.url}`
      navigator.share({
        title: this.product?.productName!,
        url: window.location.href,
      }).then();
    }
  }

  /**
   * Current product details getter
   */
  get product(): Product {
    return this.productList?.[this.productIndex]!;
  }

  /**
   * Check whether current slide is last
   */
  get isLastProduct(): boolean {
    return this.productIndex === this.productList?.length! - 1;
  }

  /**
   * Check whether current slide is first one
   */
  get isFirstProduct(): boolean {
    return this.productIndex === 0;
  }

  /**
   * On click shop now button in mobile modal
   * @param event Click event
   * @param product Product information
   */
  productShopNowClick(event: any, product: Product) {
    if(!product.affiliateUrl && !product.productUrl) {
      event.preventDefault();
    }
    if(this.homepageService.IS_IFRAME) {
      // Open modal and prevent default
      event.preventDefault();
      this.externalLinkPopup = true;
    } else {
      this.amplitudeService.productDrawerShopNowClick(product)
    }
  }

  /**
   * Showroom details getter
   */
  get showroomDetails() {
    return this.homepageService.PROPERTY_DETAILS?.showroomDetails
  }

  onSubspaceClick(event: any, modalRef: NgbActiveModal, space: SubspaceList) {
    event.preventDefault()
    this.homepageService.shouldScroll = true;
    this.router.navigate([`/${this.footerService.PropertyId}/${space?.subspaceName}`]).then(() => {
      this.isSubspaceClick = true;
      modalRef.close();
    })
    this.amplitudeService.productDrawerSpaceClick(this.product, space.title)
  }

  /**
   * Get route of current subspace to route back and to navigate to next product
   */
  getCurrentSubspaceRoute() {
    const params = this.activatedRoute.snapshot.params;

    if(params['spaceId']) {
      // is a space page
      const spaceDetails = this.homepageService.PROPERTY_DETAILS?.spaceList.find(each => each?.subspaceName! === params['spaceId'])
      if(spaceDetails) {
        const currentQueryParams  = {...this.activatedRoute.snapshot.queryParams};
        delete currentQueryParams['productId']
        return {route: `/${this.footerService.PropertyId}/${params['spaceId']}`, queryParams: currentQueryParams, spaceDetails};
      }
      return {route: `/${this.footerService.PropertyId}/`, queryParams: {}};

    } else {
      // home page, our favorites, all products, local items
      const spaceDetails = this.previousURL === 'all-products' ? this.showroomDetails?.allProductList :
        (this.previousURL === 'our-favorites' ? this.showroomDetails?.ourFavoriteProductList : (this.previousURL === 'local-items') ? this.showroomDetails?.localItemsProductList : undefined);

      const currentQueryParams  = {...this.activatedRoute.snapshot.queryParams};
      delete currentQueryParams['productId'];
      return {route: `/${this.footerService.PropertyId}/${this.previousURL}`, queryParams: currentQueryParams, spaceDetails};
    }
  }

  protected readonly getWebLink = getWebLink;
}
