import {Component} from '@angular/core';
import {HomepageService} from "../../../services/homepage/homepage.service";
import {AmplitudeService} from "../../../services/tracking/amplitude.service";
import {getWebLink} from "../../../utils/common-function";

@Component({
  selector: 'app-co-branding',
  templateUrl: './co-branding.component.html',
  styleUrl: './co-branding.component.scss'
})
export class CoBrandingComponent {

  constructor(
    public homepageService: HomepageService,
    public amplitudeService: AmplitudeService,
  ) {
  }
  get showroomDetails() {
    return this.homepageService.PROPERTY_DETAILS?.showroomDetails
  }


  get coBrandingImage() {
    //If featured image url is available then show it, otherwise fallback to cover image of the showroom.
    const featuredImage = this.showroomDetails?.featuredImage;
    return featuredImage ? featuredImage : this.showroomDetails?.image;
  }

  openLogoLink(event: any) {
    if(!this.showroomDetails?.propertyLogoLink) {
      event.preventDefault();
    }
  }

  get curatedBy(){
    if(this.showroomDetails?.curatedBy === 'account_name'){
      return this.showroomDetails?.companyName;
    } else {
      return this.showroomDetails?.showroomName;
    }
  }

  protected readonly getWebLink = getWebLink;
}
