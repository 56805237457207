let errorHandler;
function registerErrorHandler(handler) {
  errorHandler = handler;
}
function unregisterErrorHandler() {
  errorHandler = undefined;
}
const callbackWrapper = cb => {
  if (!errorHandler) {
    return cb;
  }
  const rrwebWrapped = (...rest) => {
    try {
      return cb(...rest);
    } catch (error) {
      if (errorHandler && errorHandler(error) === true) {
        return;
      }
      throw error;
    }
  };
  return rrwebWrapped;
};
function externalFunctionWrapper(func) {
  return (...args) => {
    try {
      return func(...args);
    } catch (error) {
      try {
        error._external_ = true;
      } catch (_a) {}
      throw error;
    }
  };
}
export { callbackWrapper, externalFunctionWrapper, registerErrorHandler, unregisterErrorHandler };