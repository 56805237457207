import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {Product} from "../../../interfaces/product";
import {FooterService} from "../../../services/footer/footer.service";
import {Location} from '@angular/common';
@Directive({
  selector: '[appAffiliateUrl]',
  standalone: true
})
export class AffiliateUrlDirective implements OnInit, OnChanges {
  @Input() product !: Product
  @Input() isMobile !:boolean;
  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private footerService: FooterService,
              private location: Location,
            ) { }


  ngOnInit() {
    this.addAttributeToElement()
  }

  ngOnChanges() {
    this.addAttributeToElement();
  }


  addAttributeToElement(){
    const element = this.el.nativeElement;
    const preferredAffiliatePlatform = this.product?.preferredAffiliatePlatform;
    if(element && element.tagName.toLowerCase() === 'a') {
      if(preferredAffiliatePlatform === 'AMAZON') {
        this.renderer.setAttribute(element, 'data-awinignore', '');
        this.renderer.addClass(element, 'noskimlinks');
      }
      else if(preferredAffiliatePlatform === 'IMPACT') {
        this.renderer.setAttribute(element, 'data-awinignore', '');
        this.renderer.addClass(element, 'noskimlinks');
      }
      else if (preferredAffiliatePlatform === 'AWIN') {
        if(this.isMobile){
          this.renderer.setAttribute(element, 'data-awinignore', '');
        } else{
          this.renderer.removeAttribute(element, 'data-awinignore');
        }
        this.renderer.addClass(element, 'noskimlinks');
        this.renderer.setAttribute(element, 'data-clickref',  'minoan');
        this.renderer.setAttribute(element, 'data-clickref2', `${this.footerService.PropertyDetails.id}`);
        this.renderer.setAttribute(element, 'data-clickref3', `${this.footerService.PropertyId}`);
        this.renderer.setAttribute(element, 'data-clickref4', this.location.path());
      }
      else if(preferredAffiliatePlatform === 'COMMISSION_JUNCTION'){
        this.renderer.setAttribute(element, 'data-awinignore', '');
        this.renderer.addClass(element, 'noskimlinks');
      }
      else {
        // This block handles a very specific case when there is no preffered affiliate partner selected. in this case only
        // skimlinks will be used as tracking service although that url is a part of Awin affiliate program as well that's why we are adding data-awinignore.
        // here conflicting conditions can arise like product domain is a part of awin affiliate domain and does not have preffered affiliate partner set from CMS
        this.renderer.setAttribute(element, 'data-awinignore', '');
        this.renderer.removeClass(element, 'noskimlinks');
      }
    }
    else {
      console.error("[Invalid Usage] This Directive can only be applied on Anchor Html Elements")
    }

  }
}
