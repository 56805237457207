import {afterNextRender, Component} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-minoan-website-redirect',
  standalone: true,
  imports: [],
  templateUrl: './minoan-website-redirect.component.html',
  styleUrl: './minoan-website-redirect.component.scss'
})
export class MinoanWebsiteRedirectComponent {

  constructor() {
    afterNextRender(() => {
     window.location.href = environment.minoanWebsiteUrl;
    })
  }
}
