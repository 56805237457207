import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HomepageService} from "../../services/homepage/homepage.service";
import {FooterService} from "../../services/footer/footer.service";
import {AmplitudeService} from "../../services/tracking/amplitude.service";
import {ActivatedRoute} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";
import {SkimlinksService} from "../../services/tracking/skimlinks.service";

@Component({
  selector: 'app-local-items',
  templateUrl: './local-items.component.html',
  styleUrl: './local-items.component.scss'
})
export class LocalItemsComponent implements OnInit {
  constructor(
    public homepageService: HomepageService,
    public footerService: FooterService,
    public amplitudeService: AmplitudeService,
    public activatedRoute: ActivatedRoute,
    public skimlinksService: SkimlinksService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  ngOnInit() {
    this.amplitudeService.screenImpressionEvent('local_items')
    this.homepageService.updateMetaAndTitleTags(`Shop Your Stay - ${this.homepageService.PROPERTY_DETAILS?.showroomDetails?.localItemsProductList.title!} | ${this.homepageService.PROPERTY_DETAILS?.showroomDetails.showroomName!}`, this.homepageService.PROPERTY_DETAILS?.showroomDetails?.image!)
    if(isPlatformBrowser(this.platformId)) {
      this.skimlinksService.loadAffliateLinks(`${this.footerService.PropertyId}-${this.footerService.PropertyDetails.id}`);
    }
  }

}
