import { HttpInterceptorFn } from '@angular/common/http';

export const requestInterceptor: HttpInterceptorFn = (req, next) => {
  const dt = new Date()
  const tz = dt.getTimezoneOffset();
  const authReq = req.clone({
    setHeaders: {
      utcoffset: tz.toString()
    }
  });
  return next(authReq);
};
