import { LogLevel } from '@amplitude/analytics-types';
var PREFIX = 'Amplitude Logger ';
var Logger = /** @class */function () {
  function Logger() {
    this.logLevel = LogLevel.None;
  }
  Logger.prototype.disable = function () {
    this.logLevel = LogLevel.None;
  };
  Logger.prototype.enable = function (logLevel) {
    if (logLevel === void 0) {
      logLevel = LogLevel.Warn;
    }
    this.logLevel = logLevel;
  };
  Logger.prototype.log = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    if (this.logLevel < LogLevel.Verbose) {
      return;
    }
    console.log("".concat(PREFIX, "[Log]: ").concat(args.join(' ')));
  };
  Logger.prototype.warn = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    if (this.logLevel < LogLevel.Warn) {
      return;
    }
    console.warn("".concat(PREFIX, "[Warn]: ").concat(args.join(' ')));
  };
  Logger.prototype.error = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    if (this.logLevel < LogLevel.Error) {
      return;
    }
    console.error("".concat(PREFIX, "[Error]: ").concat(args.join(' ')));
  };
  Logger.prototype.debug = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    if (this.logLevel < LogLevel.Debug) {
      return;
    }
    // console.debug output is hidden by default in chrome
    console.log("".concat(PREFIX, "[Debug]: ").concat(args.join(' ')));
  };
  return Logger;
}();
export { Logger };
