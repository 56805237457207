import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-external-link-popup',
  templateUrl: './external-link-popup.component.html',
  styleUrl: './external-link-popup.component.scss'
})
export class ExternalLinkPopupComponent {

  @Input() externalLink: boolean = false;
  @Output() externalLinkChange = new EventEmitter<boolean>();


  onHideExternalLinkModal() {
    this.externalLinkChange.emit(false);
  }


}
