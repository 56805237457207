import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink} from "@angular/router";
import {ApplyFnModule} from "../../pipes/applyFn/apply-fn.module";
import {ImageSizeModule} from "../../pipes/imageSize/image-size.module";
import {ProductDetailViewComponent} from "./product-detail-view.component";
import {PlaceholderImageModule} from "../../directives/placeholderImageDirective/placeholder-image.module";
import {ExternalLinkPopupModule} from "../external-link-popup/external-link-popup.module";
import {AffiliateUrlDirective} from "../../directives/affilateUrlDirective/affiliate-url.directive";


@NgModule({
  declarations: [ProductDetailViewComponent],
    imports: [
        CommonModule,
        PlaceholderImageModule,
        RouterLink,
        ApplyFnModule,
        ImageSizeModule,
        ExternalLinkPopupModule,
        AffiliateUrlDirective,
    ],
  exports : [ProductDetailViewComponent]
})
export class ProductDetailViewModule { }
