import { __awaiter, __generator } from "tslib";
import { createEventsIDBStore } from './events-idb-store';
import { SessionReplayTrackDestination } from '../track-destination';
import { getStorageSize } from '../helpers';
export var createEventsManager = function (_a) {
  var config = _a.config,
    sessionId = _a.sessionId,
    minInterval = _a.minInterval,
    maxInterval = _a.maxInterval,
    type = _a.type,
    payloadBatcher = _a.payloadBatcher;
  return __awaiter(void 0, void 0, void 0, function () {
    function flush(useRetry) {
      if (useRetry === void 0) {
        useRetry = false;
      }
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          return [2 /*return*/, trackDestination.flush(useRetry)];
        });
      });
    }
    var trackDestination, eventsIDBStore, sendEventsList, sendCurrentSequenceEvents, sendStoredEvents, addEvent;
    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          trackDestination = new SessionReplayTrackDestination({
            loggerProvider: config.loggerProvider,
            payloadBatcher: payloadBatcher
          });
          return [4 /*yield*/, createEventsIDBStore({
            loggerProvider: config.loggerProvider,
            apiKey: config.apiKey,
            sessionId: sessionId,
            minInterval: minInterval,
            maxInterval: maxInterval,
            type: type
          })];
        case 1:
          eventsIDBStore = _b.sent();
          sendEventsList = function (_a) {
            var events = _a.events,
              sessionId = _a.sessionId,
              deviceId = _a.deviceId,
              sequenceId = _a.sequenceId;
            if (config.debugMode) {
              getStorageSize().then(function (_a) {
                var totalStorageSize = _a.totalStorageSize,
                  percentOfQuota = _a.percentOfQuota,
                  usageDetails = _a.usageDetails;
                config.loggerProvider.debug("Total storage size: ".concat(totalStorageSize, " KB, percentage of quota: ").concat(percentOfQuota, "%, usage details: ").concat(usageDetails));
              }).catch(function () {
                // swallow error
              });
            }
            trackDestination.sendEventsList({
              events: events,
              sequenceId: sequenceId,
              sessionId: sessionId,
              flushMaxRetries: config.flushMaxRetries,
              apiKey: config.apiKey,
              deviceId: deviceId,
              sampleRate: config.sampleRate,
              serverZone: config.serverZone,
              version: config.version,
              type: type,
              onComplete: eventsIDBStore.cleanUpSessionEventsStore.bind(eventsIDBStore)
            });
          };
          sendCurrentSequenceEvents = function (_a) {
            var sessionId = _a.sessionId,
              deviceId = _a.deviceId;
            eventsIDBStore.storeCurrentSequence(sessionId).then(function (currentSequence) {
              if (currentSequence) {
                sendEventsList({
                  sequenceId: currentSequence.sequenceId,
                  events: currentSequence.events,
                  sessionId: currentSequence.sessionId,
                  deviceId: deviceId
                });
              }
            }).catch(function (e) {
              config.loggerProvider.warn('Failed to get current sequence of session replay events for session:', e);
            });
          };
          sendStoredEvents = function (_a) {
            var deviceId = _a.deviceId;
            return __awaiter(void 0, void 0, void 0, function () {
              var sequencesToSend;
              return __generator(this, function (_b) {
                switch (_b.label) {
                  case 0:
                    return [4 /*yield*/, eventsIDBStore.getSequencesToSend()];
                  case 1:
                    sequencesToSend = _b.sent();
                    sequencesToSend && sequencesToSend.forEach(function (sequence) {
                      sendEventsList({
                        sequenceId: sequence.sequenceId,
                        events: sequence.events,
                        sessionId: sequence.sessionId,
                        deviceId: deviceId
                      });
                    });
                    return [2 /*return*/];
                }
              });
            });
          };
          addEvent = function (_a) {
            var event = _a.event,
              sessionId = _a.sessionId,
              deviceId = _a.deviceId;
            eventsIDBStore.addEventToCurrentSequence(sessionId, event.data).then(function (sequenceToSend) {
              return sequenceToSend && sendEventsList({
                sequenceId: sequenceToSend.sequenceId,
                events: sequenceToSend.events,
                sessionId: sequenceToSend.sessionId,
                deviceId: deviceId
              });
            }).catch(function (e) {
              config.loggerProvider.warn('Failed to add event to session replay capture:', e);
            });
          };
          return [2 /*return*/, {
            sendCurrentSequenceEvents: sendCurrentSequenceEvents,
            addEvent: addEvent,
            sendStoredEvents: sendStoredEvents,
            flush: flush
          }];
      }
    });
  });
};
